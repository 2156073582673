import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders ,HttpParams  } from '@angular/common/http';
import { ConstantesService } from '../services/constantes.services'

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ReponseResult } from '../models/reponse_result.model';

import { StorageService } from 'src/app/services/storage.services';

@Injectable({providedIn: 'root'})
export class DataService {
  constructor(
    private httpClient: HttpClient,
    private Constantes: ConstantesService,
    private storage: StorageService,
  ) { }
 
  
  fbApi(args: any): Observable<ReponseResult> {
    let params = '';
    for(let key in args){
        params += key +'=' +args[key] +'&'  
    }
    params = args

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });    
    let options = {
      headers: httpHeaders,
    };

    let url = this.Constantes.fbApi_Endpoint + "/" + args.action;
    return this.httpClient.post<ReponseResult>(url, params, options);
  }

  executeForCRUD(args: any): Observable<ReponseResult> {
    let params = '';
    for(let key in args){
        params += key +'=' +args[key] +'&'  
    }
    params = args

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });    
    let options = {
      headers: httpHeaders,
    };

    let url = this.Constantes.FrontBack_Endpoint + "exe4crud";
    return this.httpClient.post<ReponseResult>(url, params, options);
  }

  registrarUnPinCode(args: any): Observable<ReponseResult> {
    let   me = this;
    //let codigo = args.codigo
    let user = localStorage.getItem("User")
    //const params = 'user=' + user + '&codigo=' +  codigo
    args.user = user
     let httpHeaders = new HttpHeaders({
       "Content-Type": "application/json",
     });    
     let options = {
       headers: httpHeaders,
    };
      
    let url = this.Constantes.newt_Endpoint;
    return this.httpClient.post<ReponseResult>(url, args, options);
  } 

  executeAPI(args: any): Observable<ReponseResult> {
    let me = this;
    // let endPoint = args.endPoint ||  Constantes.FrontBack_Endpoint 
    // let   bearer = args.bearer || Constantes.Bearer.access_token    
    let params = '';
    for(let key in args){
        params += key +'=' +args[key] +'&'  
    }
    params += 'UsuarioAdd=1&UsuarioUMod=1&UsuarioDel=1' 

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });    
    let options = {
      headers: httpHeaders,
    };
    let url = this.Constantes.FrontBack_Endpoint + "/api/" +  args.Action;
    return this.httpClient.post<ReponseResult>(url, params, options);
  }    


}