<!-- <h2 mat-dialog-title>
  <img src="https://d293jkhqefsxxu.cloudfront.net/assets/cat-music.svg" alt="Música y Libros">
  
  {{subTitulo}}</h2> -->
<mat-dialog-content class="mat-typography">
    <app-video-player #appVideoPlayer [videoToPlay]="arrVideos[0]" (timeUpdate)="timeUpdate($event)"></app-video-player>

    <owl-carousel-o *ngIf="arrVideos.length >1" [options]="customOptions" style="height: 25px;width: 35px;margin-left: 2px;">
        <ng-container *ngFor="let video of arrVideos; let i = index">
            <ng-template carouselSlide>
                <img (click)="openVideo(i)" class="carousel_imagen" [id]="video.Id_GtVideo" src="{{getImagen(video)}}">
                <h3 class="carousel_titulo">{{video.title}}</h3>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>