
import { Component, OnInit } from '@angular/core';
import { ConstantesService } from '../../services/constantes.services';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';
import { Meta } from '@angular/platform-browser';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogLogInComponent } from '../../dialogs/dialog-login/dialog-login.component'
import { DialogAddCodeComponent } from '../../dialogs/dialog-add-code/dialog-add-code.component'
import { DialogAddPinComponent } from '../../dialogs/dialog-add-pin/dialog-add-pin.component'


import { DialogSubscriberComponent } from '../../dialogs/dialog-subscriber/dialog-subscriber.component'


import { MatSnackBar } from '@angular/material/snack-bar'
// import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { AuthService } from '../../services/auth.service'
import { UsuarioModel } from 'src/app/models/usuario.model';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ReponseResult } from '../../models/reponse_result.model';

import { StorageService } from 'src/app/services/storage.services';

import 'firebase/auth';

declare let jQuery: any
@Component({
  selector: 'app-suscribe',
  templateUrl: './suscribe.component.html',
  styleUrls: ['./suscribe.component.css']
})
export class SuscribeComponent implements OnInit {

  public frmUser: FormGroup;
  showHeader = false;
  version = '';
  hiddenLogIn = false;
  user: any
  registrarte = false
  soyAdulto = true
  cargando = false;
  titulo = "Suscripción Básica"
  subtitulo = "Disfruta del contenido por tiempo límitado"
  suscription_type = "basica"
  mediaData = {
    Email: "",
    Session: "",
    Usuario: "",
    codigo: ""
  }
  errorMessage = ''
  successMessage = ''
  ocultarContrasenia = true
  ocultarAcceder = true
  usuario: UsuarioModel;
  constructor(
    public dialog: MatDialog,
    private Constantes: ConstantesService,
    private DataSrv: DataService,
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private meta: Meta,
    private _snackBar: MatSnackBar,
    private auth: AuthService,
    private route: ActivatedRoute,
    private storage: StorageService,
  ) {
    var me = this;
    me.version = Constantes.VER;
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)  
    let isUserLoggedIn = localStorage.getItem("Session") != null;

    let GtSubscriptionType = localStorage.getItem("GtSubscriptionType")
    
    
    me.suscription_type = this.rutaActiva.snapshot.params.suscription_type

    switch(me.suscription_type.toLowerCase()){
      case 'full':
        me.titulo="Suscripción Global Toons Full"
        me.subtitulo = "Disfruta de todo el contenido en todos tus dispositivos"
        break;
      case 'basico':
        break;
      case 'fun':
        me.titulo="Suscripción Global Toons Fun"
        me.subtitulo = "Disfruta de todo el contenido hasta en 4 dispositivos"
        break;
      case 'codigo':
        me.titulo="Suscripción Global Toons código"
        me.subtitulo = "Disfruta de todo el contenido usando tu código promocional"
        break;
      case 'pin':
        me.titulo="Suscripción Global Toons Pin"
        me.subtitulo = "Disfruta de todo el contenido en un dispositivo concurrentre"
        break;         
  }

    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });

    switch(GtSubscriptionType) {
      case 'Basico':
        switch(me.suscription_type.toLowerCase()){
          case 'full':
            me.showPagoStripe()
            break;
          case 'basico':
            me.showPagoStripe()
            break;
          case 'fun':
            me.showPagoStripe()
            break;
          case 'codigo':
            me.addCode()
            break;
          case 'pin':
            me.addPin()
            break;         
      }

        if(isUserLoggedIn){

        }
        break
      case 'Fun':
        me.acceder()
        break;
      case 'Full':
        me.acceder()
        break ;
      case 'Code':
        me.acceder()
        break;
      default:

    }
    
    
    

    

  }


  irPantallaAcceder() {

    this.router.navigate(['/acceder'])
  }
  ngOnInit() {
    let me = this
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)  
    me.usuario = new UsuarioModel();


     me.usuario.email = "";
     me.usuario.password = "";        
     me.usuario.email = "emartinez@ensamble.cc"
     me.usuario.password = "12345678";    
     
    me.frmUser = new FormGroup({
      email: new FormControl(me.usuario.email, [Validators.required, Validators.email]),
      password: new FormControl(me.usuario.password, [Validators.required]),
      nombre: new FormControl(),
    });
    me.showPagoStripe()
    // let isUserLoggedIn = localStorage.getItem("Session") != null;

    // if( isUserLoggedIn){
    //   me.acceder()
    // } 
    
    me.showHeader = true;

  }

  ejecutarTipoSuscripcion(){
    var me = this;
    switch(me.suscription_type.toLowerCase()){
      case 'full':
        me.showPagoStripe()
        break;
      case 'basico':
        me.registrarSuscripcion(1)
    
        break;
      case 'codigo':
        me.addCode()
        break;
      case 'fun':
      case 'pin':
        me.addPin()
        break;        
    }


  }



  acceder(){
    let me = this;
    const session = me.storage.getItem("Session")
    if(session.length > 0 ){
      this.router.navigate(['/browse', session])
    }else{
      me.errorMessage = "No puedes navegar al catalogo. Ocurrio un error"
    }
  }

  registrarSuscripcion( idGtSubscriptionType ) {
    let me = this
    me.errorMessage = ""
    let args = {
      "ClassName": "GtRegistrar",
      "Action": "Suscripcion",
      "Usuario": me.storage.getItem("User"),
      "Id_GtSubscriptionType": idGtSubscriptionType ,
      "db": "Prometeo"
    };
    me.DataSrv.executeForCRUD(args).subscribe(response => {
      if (response.success) {
        me.acceder()
      } else {

      }
    },  err => {
      me.errorMessage = "Hay un problema con tu conexión. Intenta nuevamente "
    })

  }
  
  showPagoStripe() {
    let me = this
    const dialogLoginRef = this.dialog.open(DialogSubscriberComponent, {
      height: '500px',
      width: '600px',
      data: me.mediaData
    });
    dialogLoginRef.afterClosed().subscribe(dilogResponse => {
      if(dilogResponse == 'NULL'){
        me.errorMessage = "La suscripción no se realizó satisfactoriamente. Intenta nuevamente "
        return;
      }

      me.auth.getSesion(dilogResponse).then(response => {
        if (response.success) {
          me.acceder()
        } else {
          me.errorMessage =  "Ocurrió un problema " + response.errorMessage
        }
      })
    });
  }
  
  addPin() {
    let me = this
    const session = me.storage.getItem("Session")
    const usuario = me.storage.getItem("User")
    if(session.length > 0 ){
      me.mediaData.Session = session
      me.mediaData.Usuario = usuario
      const dialogLoginRef = this.dialog.open(DialogAddPinComponent, {
        height: '250px',
        width: '350px',
        data: me.mediaData
      });
      dialogLoginRef.afterClosed().subscribe(result => {
        if (result.session) {
          me.acceder()
        }
      });

    }
  }
  addCode() {
    let me = this
    const session = me.storage.getItem("Session")
    const usuario = me.storage.getItem("User")
    if(session.length > 0 ){
      me.mediaData.Session = session
      me.mediaData.Usuario = usuario
      const dialogLoginRef = this.dialog.open(DialogAddCodeComponent, {
        height: '250px',
        width: '400px',
        data: me.mediaData
      });
      dialogLoginRef.afterClosed().subscribe(result => {
        if (result.session) {
          me.acceder()
        }
      });

    }
  }
  
  ngAfterViewChecked() {
    let me = this;
  

  }
  
  
  nuevoUsuario() {
    let me = this;
    me.cargando = true
    me.ocultarContrasenia = true
    me.ocultarAcceder = true    
    me.errorMessage = ''
    me.successMessage = ''
    me.usuario.email = me.frmUser.controls['email'].value
    me.usuario.password = me.frmUser.controls['password'].value
    me.usuario.nombre = me.frmUser.controls['email'].value
    me._snackBar.dismiss()
    me.auth.nuevoUsuario(me.usuario).then((response: ReponseResult) => {
      if (response.success) {
        me.hiddenLogIn = true
        me.mediaData.Usuario = response.data[0]["User"]
        me.mediaData.Email = response.data[0]["Email"]
        me.mediaData.Session = response.data[0]["Session"]
        if (response.data[0]["isValid"] == true) {
          me.successMessage  ="Actualmente cuentas con una suscripción activa que vence el " + response.data[0]["GtSubscription_Date_To"]
          me.ocultarAcceder = false
        } else {
          me.ejecutarTipoSuscripcion()
          me.registrarte = false
        } 
        return 

        //me.acceder()
      } else {
        let message = response.errorMessage
        let index = response.data.length - 1
        let errorCodeNuevoUsuario = response.data[index]["code"]
        switch(errorCodeNuevoUsuario){
          case 'EMAIL_EXISTS':
          case 'auth/email-already-in-use':
            
            me.auth.logIn(me.usuario).then((response: ReponseResult) => {
              if(!response.success){
                let errorCodeLogIn = response.data[0]["code"]
                message = response.data[0]["message"]  // "El correo ya está registrado para continuar es necesario uses la contraseña registrada."
                switch(errorCodeLogIn){
                  case 'auth/too-many-requests':
                    me.errorMessage  ="Oops! has intentado registrarte muchas veces seguidas. Espera unos minutos e intentalo nuevamente"
                    me.ocultarContrasenia = true
                    break;
                  case 'auth/email-already-in-use':
                    //me.errorMessage  ="Oops! has intentado registrarte muchas veces seguidas. Espera unos minutos e intentalo nuevamente :"
                    //me.ocultarContrasenia = true
                    break;
                  case 'INVALID_PASSWORD':
                  case 'auth/wrong-password':
                    me.errorMessage  ="El correo ya está en uso pero los datos no son correctos. Revisa la contraseña o intenta :"
                    me.ocultarContrasenia = false
                    break;                  
                }
                return
              }
                  
              if (response.data[0]["isValid"] == true) {
                me.successMessage  ="Actualmente cuentas con una suscripción activa que vence el " + response.data[0]["GtSubscription_Date_To"]
                me.ocultarAcceder = false
              } else {
                me.ejecutarTipoSuscripcion()
                me.registrarte = false
              }  
              return             
            })
            break;

        }


      }
     
      me.cargando = false
    })


  }

  recuperar() {
    let me = this;
    me.errorMessage = ''
    me.successMessage = ''
    me.usuario.email = me.frmUser.controls['email'].value
    me._snackBar.dismiss()
    if (me.usuario.email.length < 3) {

      this._snackBar.open("El correo no es válido", "Cerrar", {
        duration: 10000,
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: ['red-snackbar'],
      });

    } else {

      me.auth.changePassword(me.usuario).then((response: ReponseResult) => {
        let action = "Cerrar"
        if (response.success) {
          me.successMessage = " Revisa tu correo. Te enviamos un correo con las instrucciones para restablecer tu contraseña."

        } else {
          me.successMessage = "En caso de tener registrado tu correo, te enviaremos instrucciones para restablecer tu contraseña."
        }
        this._snackBar.open(me.successMessage, action, {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ['green-snackbar'],
        });
        return
      })
    }

  }

  setSoyAdulto() {
    let me = this;
    me.soyAdulto = true
  }
}
