<div class="contenido">
  <div class="col-md-12 text-md-center conteiner-page">
    <div
      (click)="back()"
      class="back-to-detail"
      id=" back-to-detail"
      style="opacity: 1; max-height: 50px"
    >
      <i class="fa fa-arrow-left" aria-hidden=" true "></i> Regresar a Explorar
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(100% - 50px);
      "
    >
      <app-video-player
        #appVideoPlayer
        [videoToPlay]="item"
        (timeUpdate)="timeUpdate($event)"
        (goToSuscribe)="goToSuscribe($event)"
      ></app-video-player>
    </div>
  </div>
</div>
