
import { Component } from '@angular/core';
import { ConstantesService } from '../../services/constantes.services';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute, } from '@angular/router';
import { DialogEmailComponent } from '../../dialogs/dialog-email/dialog-email.component'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

declare let jQuery: any

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent {
 
  constructor(
    public dialog: MatDialog,
    private Constantes: ConstantesService,
    private DataSrv: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    var me = this;
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)  
  }



 

}
