<app-header></app-header>

<div class="main-wrapper home-interiores">


    <div class="container ">
        <div class="row mt-5 mt-lg-0 pt-5 pt-md-0">
            <div class="col-12">
                <div class="text-center">
                    <h1 class="text-primary">Condiciones de servicio ("Condiciones")</h1>
                </div>
            </div>
        </div>
        <!-- .row -->
    </div>
    <!-- .container -->

    <div class="container ">
        <div class="row">
            <div class="col-md-12 offset-md-1 legals-content roman">
                Última actualización: 16 de noviembre de 2020 <br>
                Lea estos Términos de servicio ("Términos", "Términos de servicio") detenidamente antes de usar la
                aplicación móvil GlobalToons (el "Servicio") operada por GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V.
                ("nosotros", "nosotros" o "nuestro ").
                Su acceso y uso del Servicio está condicionado a su aceptación y cumplimiento de estos Términos. Estos
                Términos se aplican a todos los visitantes, usuarios y otras personas que acceden o utilizan el
                Servicio.
                Al acceder o utilizar el Servicio, acepta estar sujeto a estos Términos. Si no está de acuerdo con
                alguna parte de los términos, no podrá acceder al Servicio.
                <h2> Política de privacidad</h2>
                Su uso del Servicio está sujeto a la Política de privacidad de GlobalToons. Revise nuestra Política de
                privacidad, que también rige el Servicio e informa a los usuarios sobre nuestras prácticas de
                recopilación de datos.
                La política de privacidad de GlobalToons se puede encontrar aquí: <a
                    href="https://www.globaltoons.tv/privacy"> https://www.globaltoons.tv/privacy</a>
                <h2> Contenido</h2>
                El contenido de GlobalToons está disponible mediante acuerdos con productores y distribuidores, siempre
                respetando los derechos de autor y la propiedad intelectual.
                El contenido disponible tiene licencia solo para fines domésticos y se puede transmitir y descargar para
                verlo sin conexión. Solo se debe acceder al contenido descargado a través de la aplicación GlobalToons.
                GlobalToons se reserva el derecho de modificar el contenido disponible en cualquier momento.
                <h2> Suscripción</h2>
                Una suscripción da acceso a todo el contenido disponible en GlobalToons siempre que esté activo.<br>
                El pago se cargará a la cuenta de iTunes en la confirmación de la compra.<br>
                La suscripción se renueva automáticamente a menos que la renovación automática se desactive al menos 24
                horas antes del final del período actual
                Se cobrará a la cuenta la renovación dentro de las 24 horas anteriores al final del período actual, e
                identificará el costo de la renovación
                Las suscripciones pueden ser administradas por el usuario y la renovación automática puede desactivarse
                yendo a la Configuración de la cuenta del usuario después de la compra.
                Cualquier parte no utilizada de un período de prueba gratuito, si se ofrece, se perderá cuando el
                usuario compre una suscripción a esa publicación, cuando corresponda.
                La gestión de pagos se realiza mediante el sistema Apple. Puede encontrar más información aquí: <a
                    href="https://support.apple.com/es-mx/HT202039"> https://support.apple.com/es-mx/HT202039</a>
                <h2> Cuentas</h2>
                Cuando crea una cuenta con nosotros, debe proporcionarnos información precisa, completa y actualizada en
                todo momento. El no hacerlo constituye un incumplimiento de los Términos, que puede resultar en la
                cancelación inmediata de su cuenta en nuestro Servicio.
                Usted es responsable de proteger la contraseña que usa para acceder al Servicio y de cualquier actividad
                o acción bajo su contraseña, ya sea que su contraseña sea de nuestro Servicio o de un servicio de
                terceros.
                Acepta no divulgar su contraseña a ningún tercero. Debe notificarnos de inmediato cuando tenga
                conocimiento de cualquier violación de seguridad o uso no autorizado de su cuenta.
                No puede usar como nombre de usuario el nombre de otra persona o entidad o que no esté legalmente
                disponible para su uso, un nombre o marca comercial que esté sujeta a cualquier derecho de otra persona
                o entidad que no sea usted sin la debida autorización, o un nombre que sea de otro modo ofensivo, vulgar
                u obsceno.
                <h2> Propiedad intelectual</h2>
                El Servicio y su contenido original, características y funcionalidad son y seguirán siendo propiedad
                exclusiva de GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V. y sus licenciantes. El Servicio está protegido por
                derechos de autor, marcas registradas y otras leyes tanto de México como de países extranjeros. Nuestras
                marcas comerciales y nuestra imagen comercial no se pueden utilizar en relación con ningún producto o
                servicio sin el consentimiento previo por escrito de GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V.
                Enlaces a otros sitios web<br><br>
                Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni
                están controlados por GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V.
                GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V.no tiene control ni asume responsabilidad por el contenido, las
                políticas de privacidad o las prácticas de los sitios web o servicios de terceros.
                Además, reconoce y acepta que GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V. no será responsable, directa o
                indirectamente, de ningún daño o pérdida causados o supuestamente causados por o en conexión con el uso
                o la confianza en dichos contenidos, bienes o servicios disponibles en o a través de dichos sitios web o
                servicios.
                Le recomendamos encarecidamente que lea los términos y condiciones y las políticas de privacidad de
                cualquier sitio web o servicio de terceros que visite.
                <h2> Terminación</h2>
                Podemos cancelar o suspender su cuenta de inmediato, sin previo aviso ni responsabilidad, por cualquier
                motivo, incluido, entre otros, si incumple los Términos.
                Tras la terminación, su derecho a utilizar el Servicio cesará inmediatamente. Si desea cancelar su
                cuenta, simplemente puede dejar de usar el Servicio.

                <h2> Limitación de responsabilidad</h2>
                En ningún caso GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V. , ni sus directores, empleados, socios, agentes,
                proveedores o afiliados, serán responsables de ningún daño indirecto, incidental, especial, consecuente
                o punitivo, incluyendo sin limitación, pérdida de ganancias, datos , uso, plusvalía u otras pérdidas
                intangibles, como resultado de (i) su acceso o uso de o incapacidad para acceder o utilizar el Servicio;
                (ii) cualquier conducta o contenido de un tercero en el Servicio; (iii) cualquier contenido obtenido del
                Servicio; y (iv) acceso no autorizado, uso o alteración de sus transmisiones o contenido, ya sea por
                garantía, contrato, agravio (incluida la negligencia) o cualquier otra teoría legal, ya sea que se nos
                haya informado o no de la posibilidad de dicho daño, e incluso si se determina que una reparación
                establecida en este documento no ha cumplido su propósito esencial.
                <h2>Descargo de responsabilidad</h2>
                Su uso del Servicio es bajo su propio riesgo. El Servicio se proporciona "TAL CUAL" y "SEGÚN
                DISPONIBILIDAD". El Servicio se proporciona sin garantías de ningún tipo, ya sean expresas o implícitas,
                incluidas, entre otras, las garantías implícitas de comerciabilidad, idoneidad para un propósito
                particular, no infracción o curso de ejecución.
                GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V., sus subsidiarias, afiliadas y sus otorgantes de licencias no
                garantizan que a) el Servicio funcionará de manera ininterrumpida, segura o estará disponible en
                cualquier momento o lugar en particular; b) se corregirán los errores o defectos; c) el Servicio está
                libre de virus u otros componentes dañinos; o d) los resultados del uso del Servicio cumplirán con sus
                requisitos.
                Ley que rige<br><br>
                Estos Términos se regirán e interpretarán de acuerdo con las leyes de México, sin tener en cuenta sus
                disposiciones sobre conflicto de leyes.
                Nuestra falta de hacer cumplir cualquier derecho o disposición de estos Términos no se considerará una
                renuncia a esos derechos. Si alguna disposición de estos Términos se considera inválida o inaplicable
                por un tribunal, las disposiciones restantes de estos Términos permanecerán en vigor. Estos Términos
                constituyen el acuerdo completo entre nosotros con respecto a nuestro Servicio y reemplazan y reemplazan
                cualquier acuerdo anterior que podamos tener entre nosotros con respecto al Servicio.
                <h2>Cambios</h2>
                Nos reservamos el derecho, a nuestro exclusivo criterio, de modificar o reemplazar estos Términos en
                cualquier momento. Así que por favor regrese y revíselos. Si una revisión es importante, le
                notificaremos antes de que entren en vigencia los nuevos términos. Lo que constituye un cambio material
                se determinará a nuestro exclusivo criterio.
                Al continuar accediendo o utilizando nuestro Servicio después de que esas revisiones entren en vigencia,
                usted acepta estar sujeto a los términos revisados. Si no está de acuerdo con los nuevos términos, deje
                de usar el Servicio.

                <h2> Contáctenos</h2>
                Si tiene alguna pregunta sobre estos Términos, comuníquese con nosotros. <a
                    href="mailto:soporte@globaltoons.tv  ">soporte@globaltoons.tv </a>



            </div>
            <!-- .col -->
        </div>
        <!-- .row -->
    </div>
    <!-- .container -->
</div>



<app-footer></app-footer>