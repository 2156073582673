
import { Component,  OnInit,Input  } from '@angular/core';
import { StorageService } from '../../services/storage.services';

import { DialogMediaItemComponent } from '../../dialogs/dialog-media-item/dialog-media-item.component'
import { DialogMusicItemComponent } from '../../dialogs/dialog-music-item/dialog-music-item.component'
import { DialogCraftItemComponent } from '../../dialogs/dialog-craft-item/dialog-craft-item.component'

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';

@Component({
  selector: 'app-card-media-episode',
  templateUrl: './card-media-episode.component.html',
  styleUrls: ['./card-media-episode.component.css']
})
export class CardMediaEpisodeComponent implements OnInit {

  @Input() item ;
  classCard='large';
  showLock=true
  GtSubscriptionType ='Basico'
  constructor(
    public dialog: MatDialog,
    private storage: StorageService,
    private router: Router,
    ) {
    var me = this;
    me.verificaCandado()
  }
  verificaCandado(){
    var me = this;
    me.GtSubscriptionType = localStorage.getItem("GtSubscriptionType")
    
    if(!me.GtSubscriptionType || me.GtSubscriptionType == 'undefined') me.GtSubscriptionType = "Basico"

    // console.log(me.GtSubscriptionType)
    me.showLock=true
    if(me.GtSubscriptionType){

      switch(me.GtSubscriptionType.toLowerCase()){
        case 'fun':
        case 'codigo':
        case 'pin':
        case 'full':
          me.showLock=false
          break;
        case 'basico':
          me.showLock=true
          break;
        default:
          me.showLock=true
          break;

      }
    }
    // console.log( me.showLock)
  }
  ngOnInit() {
    let me = this;
    me.verificaCandado()
    if(me.item.type == 'musicals'){
      me.classCard='middle';
    }
  }

  showWatch(): void {
    let me = this
    let media = me.item
    me.storage.store("Episode", JSON.stringify(media) );
    this.router.navigate(['/watch', me.stringToSlug(media.title_Media), me.stringToSlug(media.title)])
  }

  stringToSlug (str:string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  openDialogMedia(): void {
    let me = this
    return;
    // let media = me.item
    // var name = ''
    // var email = ''
    // var mediaData = {
    //   id: media.id,
    //   title: media.title,
    //   synopsis: media.synopsis,
    //   poster: media.poster,
    // }

    // let dialogRef

    // switch(media.type){
    //   case 'musicals':
    //     dialogRef = this.dialog.open(DialogMusicItemComponent, {
    //       width: '700px',
    //       data: mediaData
    //     });
    //     break;
    //   case 'crafts':
    //     dialogRef = this.dialog.open(DialogCraftItemComponent, {
    //       width: '700px',
    //       data: mediaData
    //     });        
    //     break;
    //   default:
    //     dialogRef = this.dialog.open(DialogMediaItemComponent, {
    //       width: '700px',
    //       data: mediaData
    //     });
    //     break;
    // }



    // dialogRef.afterClosed().subscribe(result => {
    //   let me = this;
    
    // });

  }
}