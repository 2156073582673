import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar'
import { AuthService } from '../../services/auth.service'
import { UsuarioModel } from '../../models/usuario.model';
import { ReponseResult } from '../../models/reponse_result.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { DialogLogInComponent } from '../../dialogs/dialog-login/dialog-login.component'

import { StorageService } from '../../services/storage.services';

declare var stripe: any;
export interface DialogData {
  id: string;
  title: string;
  synopsis: string;
  poster: string;
  email: string;
  name: string;
  password: string;
  Usuario:string ; 
  codigo:string ;    
}



@Component({
  selector: 'dialog-subscriber',
  templateUrl: './dialog-subscriber.component.html',
  styleUrls: ['./dialog-subscriber.component.css'],
})
export class DialogSubscriberComponent implements OnInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  public frmPin: FormGroup;
  public frmUser: FormGroup;
  public frmCodigo : FormGroup;
  btnSuscribirDisabled = true
  customerData: any;
  user: any
  email:any
  error = ''
  cargando = false;
  dataItem: {
    id: string;
    title: string;
    synopsis: string;
    poster: string;
    email: string;
    name: string;
    password: string;
    Usuario:string ; 
    codigo:string ;     
  };
  usuario: UsuarioModel;
  registrarte = true

  _totalAmount: number;
  card: any;

  cardError: string;
  planes = [

    
    // { "planId": "plan_H0XVs8wWWAq9og", "planNombre": "Debug Mensual MXN 63.00", "isTest":true },
    // { "planId": "price_1H9c35IRhBAziICW2yNaQrE0", "planNombre": "Prueba 1 mes MXN 11.00", "isTest":false },
    // { "planId": "price_1H9bwoIRhBAziICWJ0yNiSnz", "planNombre": "Mensual MXN 65.00", "isTest":false },
    // { "planId": "price_1H9c0fIRhBAziICWsUmfjMrr", "planNombre": "Semestral MXN 365.00", "isTest":false },
    // { "planId": "price_1H9c1LIRhBAziICW6m1cpZ2t", "planNombre": "Anual MXN 735.00 ", "isTest":false }
    { "planId": "price_1I9uMIIRhBAziICW42m3S1xe", "planNombre": "Pago Mensual MXN 25.00 ", "isTest":false }
  ]
  formaPago = "pagoTdc"
  planSeleccionado = "price_1I9uMIIRhBAziICW42m3S1xe"
  
  monto = "25.00"
  reference = "NULL"
  barcode_url = ""
  constructor(
    private firestore: AngularFirestore,
    private fireAuth: AngularFireAuth,
    private DataSrv: DataService,
    private _snackBar: MatSnackBar,
    private storage: StorageService,
    public dialogRef: MatDialogRef<DialogSubscriberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private auth: AuthService,
    public dialog: MatDialog,
  ) {
    let me = this
    me.dataItem = data
    me.user = localStorage.getItem("User")
    me.email = localStorage.getItem("Email")
    this._totalAmount = data['totalAmount'];

    firebase.auth().onAuthStateChanged(async (user) => {
      
      if (user) {
        me.user = user
        await firebase.firestore().collection('stripe_customers')
          .doc(me.user.uid)
          .onSnapshot((snapshot) => {
            me.customerData = snapshot.data()
            me.btnSuscribirDisabled = false
          })

      } else {
        me.openDialogLogIn()
      }
    });
  }
  tengoCodigo(){
    this.formaPago = 'codigo'
    return false
  }
  generarOxxo(){
    let me = this;

    let args = {
      "email": me.email,
      "gt_subscriber":me.user,
      "action":  "order/oxo"  ,
    };
    me.cargando = true
    me.DataSrv.fbApi(args).subscribe((response :any ) => {
    //console.log("🚀 ~ file: dialog-subscriber.component.ts ~ line 127 ~ DialogSubscriberComponent ~ me.DataSrv.registrarUnPinCode ~ response", response)
      me.cargando = false
      if (response.success) {
        var json = response.json
        me.reference = json.payment_method.reference
        me.barcode_url= json.payment_method.barcode_url

      }else{
        //me.error = response.errorMessage
        // let message = response.errorMessage
        // let action ="Cerrar"
        // this._snackBar.open(message, action, {
        //   duration: 10000,
        //   horizontalPosition: "center",
        //   verticalPosition: "top",
        //   panelClass: ['red-snackbar'],
        // });

      }
    }); 
  }
  addCodigo(): void {
    let me = this;

    let args = {
      "ClassName": "GtRegistrar",
      "Action": "Codigo",
      "Codigo":   me.frmCodigo.controls['codigo'].value   ,
      "Usuario":  me.dataItem.Usuario,
      "db": "Prometeo"
    };
    me.DataSrv.executeForCRUD(args).subscribe(response => {
      if (response.success) {
        me.storage.store("Session", response.data[0].Session );
        me.storage.store("User", response.data[0].user );        
        let responseResult = {
          action: '1',
          session:response.data[0].Session
        }
        this.dialogRef.close(responseResult);
      }else{
        //me.error = response.errorMessage
        let message = response.errorMessage
        let action ="Cerrar"
        this._snackBar.open(message, action, {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ['red-snackbar'],
        });

      }
    });    
  }  
  addPin(): void {
    let me = this;

    let args = {
      "pin":   me.frmPin.controls['codigo'].value   ,
    };
    me.DataSrv.registrarUnPinCode(args).subscribe(response => {
      if (response.success) {
        me.storage.store("Session", response.data[0].Session );
        me.storage.store("User", response.data[0].user );        
        let responseResult = {
          action: '1',
          session:response.data[0].Session
        }
        this.dialogRef.close(responseResult);
      }else{
        //me.error = response.errorMessage
        let message = response.errorMessage
        let action ="Cerrar"
        this._snackBar.open(message, action, {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ['red-snackbar'],
        });

      }
    });    
    //this.dialogRef.close("");
  }

  openDialogLogIn() {
    let me = this
    var mediaData = {
      registrarte: false
    }
    const dialogLoginRef = this.dialog.open(DialogLogInComponent, {
      height: '400px',
      width: '400px',
      data: mediaData
    });

    dialogLoginRef.afterClosed().subscribe(async (result) => {
      await firebase.firestore().collection('stripe_customers')
        .doc(me.user.uid)
        .onSnapshot((snapshot) => {
          me.customerData = snapshot.data()
          me.btnSuscribirDisabled = false
        })

    });
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  cardHandler(e) {
  }

  ngAfterViewInit() {
    this.initiateCardElement();
  }

  ngOnInit() {
    let me = this;
    this.frmCodigo = new FormGroup({
      codigo: new FormControl('', ),
    });    
    // me.usuario = new UsuarioModel();
    // me.usuario.email = "erikmr.mx@gmail.com";
    // me.usuario.password = "123456";
    // me.frmUser = new FormGroup({
    //   email: new FormControl(me.usuario.email, [Validators.required, Validators.email]),
    //   password: new FormControl(me.usuario.password, [Validators.required]),
    //   nombre: new FormControl(),
    // });
    this.frmPin = new FormGroup({
      codigo: new FormControl('', ),
    });
  }

  initiateCardElement() {
    let me = this

    const elements = stripe.elements({
      locale: "es"
    });
    const cardStyle = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    const options = {
      hidePostalCode: true,
      hideIcon: false,
      iconStyle: "solid",
    }

    this.card = elements.create('card', options);
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  onChange({ error }) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.card.detectChanges();
  }

  async createStripeToken() {
    const { token, error } = await stripe.createToken(this.card);
    if (token) {
      this.onSuccess(token);
    } else {
      this.onError(error);
    }
  }

  zeroDecimalCurrency(amount, currency) {
    let zeroDecimalCurrency = true;
    return zeroDecimalCurrency;
  }


  formatAmountForStripe(amount, currency) {
    amount = 10
    currency = 'mxn'
    return this.zeroDecimalCurrency(amount, currency) ?
      amount :
      Math.round(amount * 100);
    // return  0
  }

  async onSubmit(event) {
    let me = this;
  }


  async onSuccess(token) {
    let me = this
    me.cargando = true

    const { setupIntent, error } = await stripe.confirmCardSetup(
      me.customerData.setup_secret, {
      payment_method: {
        card: this.card,
        billing_details: {
          name: "cardholderName",
        },
      },
    });


    await firebase.firestore()
      .collection('stripe_customers')
      .doc(me.user.uid)
      .collection('payment_methods')
      .add({ id: setupIntent.payment_method });

      
    const amount = 1
    const currency = 'mxn'
    const data = {
      payment_method: setupIntent.payment_method,
      suscription_plan: me.planSeleccionado,
      currency,
      amount: this.formatAmountForStripe(amount, currency),
      status: 'new',
    };

    await firebase.firestore()
      .collection('stripe_customers')
      .doc(me.user.uid)
      .collection('payments')
      .add(data)

    await firebase.firestore()
      .collection('stripe_customers')
      .doc(me.user.uid)
      .collection('payments')
      .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          const payment = doc.data();
          if (payment.metadata) {
            me.cargando = false
            this.dialogRef.close(payment.metadata.gt_subscriber);
          }
          if(payment.error){
            me.cargando = false
            this._snackBar.open(payment.error, "Cerrar", {
              duration: 10000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ['red-snackbar'],
            });            
          }
        })
      })


  }

  onError(error) {
    if (error.message) {
      this.cardError = error.message;
    }
  }
  closeOxxo(): void {
    let me = this;
    let responseResult = {
      action: '1',
      session: "1257Gsylld5698dmc"
    }    
    this.dialogRef.close(responseResult);
  }

  onNoClick(): void {
    let me = this;
    this.dialogRef.close("NULL");
  }

}
