import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.services';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';

import { DataService } from '../../services/data.service';
declare let jQuery: any
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  colorSeccion = 'bg-verde'
  colorSeccion2 = 'bg-morado'
  arrVideos = [{
    Id_GtMedia: 0,
    Id_GtVideo: 0,
    poster: "",
    synopsis: "",
    title: "",
    type: "video/mp4",
    url: "",
  }];
  titulo = "";
  subTitulo = "";
  videoActual : any
  openPlayer = false;
  item :any

  constructor(
    private storage: StorageService,
    private router: Router,
    private DataSrv: DataService,
  ) {
    let me = this;
    me.item = JSON.parse(storage.getItem("Media"))
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(100, 0)
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      jQuery("#hiddenToolBar").val(1)
      window.scrollTo(100, 0)
    });     

    let args = {
      "ClassName": "GtVideos_X_Media_V2",
      "Action": "Get",
      "Id_GtMedia": me.item.id,
      "Session" : me.storage.getItem("Session") ,
      "db": "Prometeo"
    };
    me.DataSrv.executeForCRUD(args).subscribe(response => {
      if (response.success) {
        
        me.arrVideos = response.data
        me.openPlayer = true;
        me.videoActual = me.arrVideos[0]
        me.subTitulo = me.arrVideos[0].title
        //me.autoPlay();
      }
    });

   }

  ngOnInit(): void {
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)
  }

  ngAfterViewInit(): void {


  }

  openDetail():void{
    let me = this
    window.scrollTo(300, 0)
    // jQuery("#pageTop").addClass("hide")
    // jQuery("#scrolling").addClass("hide")
    jQuery("#hiddenToolBar").val(0)
    // window.scrollTo(10, 0)
    // window.scrollTo(0, 0)
 
    let media:any =  me.arrVideos[0]
    me.storage.store("Episode", JSON.stringify(media) );
    
    this.router.navigate(['/watch', me.stringToSlug(media.title_Media), me.stringToSlug(media.title)])    
  }
  stringToSlug (str:string) {
    if(!str || str.length == 0 || str == 'nothing') return ''
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
  }  
}
