<div class="content" style="min-height: 500px">
  <div><br /><br /></div>
  <div
    class="row {{ colorSeccion }} flex flex-column flex-md-row"
    style="
      margin-top: 20px;
      background-image: url(assets/img/background/avator-bg.png);
    "
  >
    <div
      class="col-12 col-md-3 justify-content-center align-items-center"
      style="display: flex"
    >
      <img
        class="poster mx-auto mx-md-0"
        [src]="item.poster"
        alt="{{ item.title }}<"
      />
    </div>
    <div class="col-12 col-md-5 text-center text-md-end">
      <div class="media-body">
        <h3 class="text-white-title">{{ item.title }}</h3>
        <p class="text-white-synopsis">{{ item.synopsis }}</p>
        <div class="text-white-detalle">
          <b> Calsificación :</b> {{ item.Classification }} &nbsp;&nbsp;
          <b>Pais: </b> {{ item.Country_Code }} <br />
          <b>Copyright: </b>{{ item.copyright }} <br />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4" style="text-align: center">
      <div class="detalle">
        <button (click)="openDetail()" type="button" class="button--play">
          <i class="fa fa-play" aria-hidden="true"></i>
        </button>
        <div class="text-white-detalle">
          <b> Calsificación :</b> {{ item.Classification }} <br />
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="col-12"></div>
  </div>
  <app-carrousel [arrToPrint]="arrVideos"></app-carrousel>
</div>
