<!-- ====================================
      ———	FOOTER
      ===================================== -->
<footer id="footer" class="footer-bg-white bg-light ">
    <!-- Footer Color Bar -->
    <div class="color-bar ">
        <div class="container-fluid ">
            <div class="row ">
                <div class="col color-bar bg-warning "></div>
                <div class="col color-bar bg-danger "></div>
                <div class="col color-bar bg-success "></div>
                <div class="col color-bar bg-info "></div>
                <div class="col color-bar bg-purple "></div>
                <div class="col color-bar bg-pink "></div>
                <div class="col color-bar bg-warning d-none d-md-block "></div>
                <div class="col color-bar bg-danger d-none d-md-block "></div>
                <div class="col color-bar bg-success d-none d-md-block "></div>
                <div class="col color-bar bg-info d-none d-md-block "></div>
                <div class="col color-bar bg-purple d-none d-md-block "></div>
                <div class="col color-bar bg-pink d-none d-md-block "></div>
            </div>
        </div>
    </div>

    <div class="py-8 ">
        <div class="container ">
            <div class="row ">
                <div class="col-sm-7 col-lg-7  col-xs-7 " style="text-align: center;">
                    <a class="mb-6 d-block " href="#">
                        <img class="img-fluid d-inline-block  lazyestload " data-src="https://global-toons.imgix.net/www/logo_300.png?w=250&H=300 " src="https://global-toons.imgix.net/www/toolbar_logo.svg?w=250&H=300">

                    </a>
                    <p class="mb-6 ">
                        {{'Global Toons es una plataforma 100% segura y de uso sencillo e intuitivo en la que niñas y niños se divierten y exploran el mundo a través de contenido exclusivo curado especialmente para ellos.' | translate}}


                    </p>
                    <div class="wow fadeInUp">
                        <div class="text-center mt-1">
                            <a target="blank_" href="https://www.facebook.com/globaltoons">
                                <img src="https://global-toons.imgix.net/www/botones/redessociales_facebook.png?w=150&H=100" alt="Facebook" />
                            </a>
                            <a target="blank_" href="https://www.instagram.com/globaltoons/">
                                <img src="https://global-toons.imgix.net/www/botones/redessociales_instragram.png?w=150&H=100" alt="Instragram" />
                            </a>
                            <a target="blank_" href="https://www.linkedin.com/company/global-toons/?originalSubdomain=mx">
                                <img src="https://global-toons.imgix.net/www/botones/redessociales_linkedin.png?w=150&H=100" alt="Linkedin" />
                            </a>
                            <a target="blank_" href="https://twitter.com/globaltoons ">
                                <img src="https://global-toons.imgix.net/www/botones/redessociales_twitter.png?w=150&H=100" alt="Twitter" />
                            </a>
                            <a target="blank_" href="https://www.youtube.com/channel/UCWU36Oq2YeMhQWreeyzxy-g">
                                <img src="https://global-toons.imgix.net/www/botones/redessociales_youtube.png?w=150&H=100" alt="Youtube" />
                            </a>
                            <a target="blank_" href="https://www.kidsafeseal.com/certifiedproducts/globaltoons.html">
                                <img src="https://global-toons.imgix.net/logos/kidsafe.png?w=150&H=100" alt="kidsafeseal | kid safe" />
                            </a>
                        </div>
                    </div>                    


                </div>
                <div class=" col-sm-2 col-lg-2 col-xs-2">&nbsp;</div>
                <div class=" col-sm-3 col-lg-3 col-xs-3">
                    <h4 class="section-title-sm mb-6 font-weight-bold text-warning ">Ligas de interés</h4>
                    <ul class="list-unstyled ">
                        <li class="mb-4 ">
                            <a href="./privacy ">
                                <i class="fa fa-angle-double-right mr-2 " aria-hidden="true "></i>{{'Política de privacidad' | translate }}

                            </a>
                        </li>
                        <li class="mb-4 ">
                            <a href="./terminos">
                                <i class="fa fa-angle-double-right mr-2 " aria-hidden="true "></i>{{'Condiciones de uso' | translate }}

                            </a>
                        </li>
                        <li class="mb-4 ">
                            <a href="mailto:soporte@globaltoons.tv">
                                <i class="fa fa-angle-double-right mr-2 " aria-hidden="true "></i>{{'Soporte' |translate}}
                            </a>
                        </li>
                        <li class="mb-4 ">
                            <a href="./about">
                                <i class="fa fa-angle-double-right mr-2 " aria-hidden="true "></i>{{'Acerca de Global Toons' | translate }}

                            </a>
                        </li>
                        <li>
                            <br>
                            <a href="https://appgallery.huawei.com/#/app/C101017659">
                                <img src="https://global-toons.imgix.net/www/botones/tiendas_appgallery.png?w=150" alt="Huawei" />
                            </a>
   
                        </li>
                        <li>
                            <br>
                            <a href="https://apps.apple.com/us/app/global-toons/id1497217074?ls=1">
                                <img src="https://global-toons.imgix.net/www/botones/tiendas_appstore.png?w=150" alt="Apple" />
                            </a>

                        </li>
                        <li>
                            <br>
                            <a href="https://play.google.com/store/apps/details?id=app.globaltoons.tv">
                                <img src="https://global-toons.imgix.net/www/botones/tiendas_googleplay.png?w=150" alt="Google" />
                            </a>

                        </li>
                    </ul>
                </div>




            </div>
        </div>
    </div>

    <!-- Copy Right -->
    <div class=" copyright ">
        <div class=" container ">
            <div class=" row py-4 align-items-center ">
                <div class=" col-sm-7 col-xs-12 order-1 order-md-0 ">
                    <p class=" text-center text-sm-left font-size-13 mb-0 "> 
                        {{version}} © {{'© 2020 Todos los derechos reservados' | translate }} GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V.</p>
                </div>

                <div class=" col-sm-5 col-xs-12 ">

                    <!-- <ul class=" list-inline d-flex align-items-center justify-content-md-end justify-content-center mb-md-0 ">
                        <li class=" mr-2 ">
                            <a class="icon-rounded-circle-small bg-facebook " href="https://www.facebook.com/globaltoons">
                                <i class="fa fa-facebook" aria-hidden="true "></i>
                            </a>
                        </li>
                        <li class=" mr-2 ">
                            <a class="icon-rounded-circle-small bg-youtube " href="https://www.youtube.com/channel/UCWU36Oq2YeMhQWreeyzxy-g">
                                <i class="fa fa-youtube" aria-hidden="true "></i>
                            </a>
                        </li>
                        <li class=" mr-2 ">
                            <a class="icon-rounded-circle-small bg-instragram " href="https://www.instagram.com/globaltoons/">
                                <i class="fa fa-instagram" aria-hidden="true "></i>
                            </a>
                        </li>
                        <li class=" mr-2 ">
                            <a class="icon-rounded-circle-small bg-twitter " href="https://twitter.com/globaltoons ">
                                <i class="fa fa-twitter  " aria-hidden="true "></i>
                            </a>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</footer>