
import { Component, OnInit } from '@angular/core';
import { ConstantesService } from '../../services/constantes.services';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { Meta } from '@angular/platform-browser';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogLogInComponent } from '../../dialogs/dialog-login/dialog-login.component'
import { DialogAddCodeComponent } from '../../dialogs/dialog-add-code/dialog-add-code.component'
import { DialogSubscriberComponent } from '../../dialogs/dialog-subscriber/dialog-subscriber.component'
import { MatSnackBar } from '@angular/material/snack-bar'
// import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { AuthService } from '../../services/auth.service'
import { UsuarioModel } from '../../models/usuario.model';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ReponseResult } from '../../models/reponse_result.model';
import { StorageService } from 'src/app/services/storage.services';
import 'firebase/auth';
declare let jQuery: any

@Component({
  selector: 'app-acceder',
  templateUrl: './acceder.component.html',
  styleUrls: ['./acceder.component.css']
})
export class AccederComponent implements OnInit {
  public frmUser: FormGroup;
  showHeader = false;
  version = '';
  hiddenLogIn = false;
  user: any
  registrarte = false
  soyAdulto = true
  error = ''
  cargando = false;
  mediaData = {
    Email: "",
    Session: "",
    Usuario: "",
    codigo: ""
  }
  successMessage = ''
  errorMessage = ''
  ocultarContrasenia = true
  ocultarSuscribir = true  
  usuario: UsuarioModel;
  constructor(
    // private firestore: AngularFirestore,
    private fireAuth: AngularFireAuth,
    public dialog: MatDialog,
    private Constantes: ConstantesService,
    private DataSrv: DataService,
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private meta: Meta,
    private _snackBar: MatSnackBar,
    private auth: AuthService,
    private route: ActivatedRoute,
    private storage: StorageService,
  ) {
    var me = this;
    me.version = Constantes.VER;

    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });    
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)   
  }
  ngOnInit() {
    let me = this
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)   
    me.usuario = new UsuarioModel();

    // me.usuario.email = "erikmr.mx@gmail.com";
    // me.usuario.password = "12345678";
     me.usuario.email = "";
     me.usuario.password = "";        

    me.frmUser = new FormGroup({
      email: new FormControl(me.usuario.email, [Validators.required, Validators.email]),
      password: new FormControl(me.usuario.password, [Validators.required]),
      nombre: new FormControl(),
    });

    me.showHeader = true;
  }



  acceder(){
    let me = this;
    me.successMessage = ''
    me.errorMessage = ''
    me.ocultarContrasenia = true
    me.ocultarSuscribir = true      
    const session = me.storage.getItem("Session")
    if(session && session.length > 0 ){
      this.router.navigate(['/browse', 'series_y_peliculas'])
    }else{
      me.errorMessage = "No puedes navegar al catalogo. Ocurrio un error"
    }
  }

  showPagoStripe() {
    let me = this
    const dialogLoginRef = this.dialog.open(DialogSubscriberComponent, {
      height: '500px',
      width: '600px',
      data: me.mediaData
    });
    dialogLoginRef.afterClosed().subscribe(dilogResponse => {
      if(dilogResponse == 'NULL'){
        me.errorMessage = "La suscripción no se realizó satisfactoriamente. Intenta nuevamente "
        return;
      }

      me.auth.getSesion(dilogResponse).then(response => {
        if (response.success) {
          me.acceder()
        } else {
          me.errorMessage =  "Ocurrió un problema " + response.errorMessage
        }
      })
    });
  }
  
  ngAfterViewChecked() {
    let me = this;

  }
  suscribir() {

    this.router.navigate(['/suscribe/full'])
  }
  logIn() {
    let me = this;
    me.cargando = true

    me.storage.clear("GtSubscriptionType");
    me.storage.clear("Session");
    me.storage.clear("User");
    me.storage.clear("Email");
    
    me.storage.clear("Media");
    me.storage.clear("Episode");

    me.successMessage = ''
    me.errorMessage = ''
    me.ocultarContrasenia = true
    me.ocultarSuscribir = true  
        
    let message = ''
    me.showHeader = false;
    me.successMessage = ''
    me.usuario.email = me.frmUser.controls['email'].value
    me.usuario.password = me.frmUser.controls['password'].value
    me.auth.logIn(me.usuario).then((response: ReponseResult) => {
        me.cargando = false
        if(!response.success){
          let errorCodeLogIn = response.data[0]["code"]
          message = response.data[0]["message"]  // "El correo ya está registrado para continuar es necesario uses la contraseña registrada."
          switch(errorCodeLogIn){
            case 'auth/too-many-requests':
              me.errorMessage  ="Oops! has intentado registrarte muchas veces seguidas. Espera unos minutos e intentalo nuevamente"
              me.ocultarContrasenia = true
              break;
            case 'auth/email-already-in-use':
              //me.errorMessage  ="Oops! has intentado registrarte muchas veces seguidas. Espera unos minutos e intentalo nuevamente :"
              //me.ocultarContrasenia = true
              break;

            case 'auth/wrong-password':
              me.errorMessage  ="Los datos no son correctos. Revisa la contraseña o intenta :"
              me.ocultarContrasenia = false
              break;                  
            case 'EMAIL_NOT_FOUND':
            case 'auth/user-not-found':
              me.errorMessage  ="No hay una suscripción regsitrada con ese correo."
              me.ocultarSuscribir = false
              break;                  

          }
          return
                   
        }
        me.ocultarSuscribir = false
        me.errorMessage  ="No cuentas con una suscripción activa. Necesitas registara una nueva suscripción"
        if (response.data[0]["isValid"] == true) {
          me.acceder()
        }else{
          me.ocultarSuscribir = false
          me.errorMessage  ="No cuentas con una suscripción activa. Necesitas registara una nueva suscripción"
        }
        return
                  
    })
  }


  recuperar() {
    let me = this;
    me.error = ''
    me.successMessage = ''
    me.usuario.email = me.frmUser.controls['email'].value
    // me.usuario.password = me.frmUser.controls['password'].value
    // me.usuario.nombre = me.frmUser.controls['nombre'].value
    me._snackBar.dismiss()
    if (me.usuario.email.length < 3) {

      this._snackBar.open("El correo no es válido", "Cerrar", {
        duration: 10000,
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: ['red-snackbar'],
      });

    } else {

      me.auth.changePassword(me.usuario).then((response: ReponseResult) => {
        let action = "Cerrar"
        if (response.success) {
          me.successMessage = " Te enviaremos por correo instrucciones para restablecer tu contraseña"

        } else {
          me.successMessage = "En caso de tener registrado tu correo, te enviaremos instrucciones para restablecer tu contraseña"
        }
        this._snackBar.open(me.successMessage, action, {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ['green-snackbar'],
        });
        return
      })
    }

  }
  setSoyAdulto() {
    let me = this;
    me.soyAdulto = true
  }
}
