import { Injectable } from '@angular/core';

//import * as moment from 'moment';
//import 'moment/locale/pt-br';

@Injectable({providedIn: 'root'})
export class ConstantesService {
    constructor() {}
    RTE =  'prod'
    setDefaultValues = false
    VER = "2.2.7"
    PLATFORM = "WEB"
    Bearer =  "";
    //FrontBack_Endpoint = this.RTE == 'qa' ? 'https://llv75fu2u6.execute-api.us-west-1.amazonaws.com/gt/api2/':  'https://llv75fu2u6.execute-api.us-west-1.amazonaws.com/gt/api2/';
    FrontBack_Endpoint = this.RTE == 'qa' ? 'https://fb.globaltoons.tv/api/gt/':  'https://fb.globaltoons.tv/api/gt/';
    newt_Endpoint = this.RTE == 'qa' ? 'https://tc8xz1b1r0.execute-api.us-west-1.amazonaws.com/default/newt':  'https://tc8xz1b1r0.execute-api.us-west-1.amazonaws.com/default/newt';
    fbApi_Endpoint = this.RTE == 'qa' ? 'https://fb.globaltoons.tv/api/gt':  'https://fb.globaltoons.tv/api/gt';

  
}