import { Component, Inject ,OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import {MatSnackBar} from '@angular/material/snack-bar'

import { StorageService } from 'src/app/services/storage.services';
export interface DialogData  {
  Usuario:string ; 
  codigo:string ; 
}



@Component({
  selector: 'dialog-add-code',
  templateUrl: './dialog-add-code.component.html',
  styleUrls: ['./dialog-add-code.component.css'],
})
export class DialogAddCodeComponent implements OnInit{
  error= ''
  public ownerForm: FormGroup;
  dataItem:{
    Usuario:string ; 
    codigo:string ; 
  };

  constructor(
    private DataSrv: DataService,
    private _snackBar: MatSnackBar,
    private storage: StorageService,
    public dialogRef: MatDialogRef<DialogAddCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      let me = this
      me.dataItem = data
      
     }


  ngOnInit() {
    this.ownerForm = new FormGroup({
      codigo: new FormControl('', ),
    });
  }

  addCodigo(): void {
    let me = this;

    let args = {
      "ClassName": "GtRegistrar",
      "Action": "Codigo",
      "Codigo":   me.ownerForm.controls['codigo'].value   ,
      "Usuario":  me.dataItem.Usuario,
      "db": "Prometeo"
    };
    me.DataSrv.executeForCRUD(args).subscribe(response => {
      if (response.success) {
        me.storage.store("Session", response.data[0].Session );
        me.storage.store("User", response.data[0].user );        
        let responseResult = {
          action: '1',
          session:response.data[0].Session
        }
        this.dialogRef.close(responseResult);
      }else{
        //me.error = response.errorMessage
        let message = response.errorMessage
        let action ="Cerrar"
        this._snackBar.open(message, action, {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ['red-snackbar'],
        });

      }
    });    
  }
 
  onNoClick(): void {
    let me = this;
    this.dialogRef.close("");
  }
  createOwner(value){
  }

}
