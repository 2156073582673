<mat-card class="media-card " (click)="showWatch()" >
    <div class="divImage">
        <button type="button" class="button--play"><i class="fa fa-play" aria-hidden="true"></i>
             <i *ngIf="showLock"  [ngClass]="{'fa fa-lock-open': item.freeTime === 100 , 'fa fa-lock red': item.freeTime < 100 }"  ></i>  
        </button>
        <img  class="img-card" [src]="item.poster" alt="{{item.title}}">
    </div>
    <mat-card-content class="sinopsys-card" (click)="openDialogMedia()">

        <mat-card-title class="title-card">{{item.title}}</mat-card-title>
        {{item.synopsis}}
    </mat-card-content>
</mat-card>
