<div class="card  card-hover scrolling {{class_bg}} ">
    <div class="card-body text-center p-0 ">
        <div class="card-icon-border-large {{class_border}}">
            <img class="imagenSeccion" [src]="imagePath" />
        </div>
        <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis ">{{titulo}}
        </h2>
        <div  >
            <a [routerLink]="['/browse/', cardName]" >
                <i class="vermas fa fa-chevron-down " aria-hidden="true "></i>
            </a>
        </div>

    </div>
</div>