import { Component, OnInit,ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { StorageService } from 'src/app/services/storage.services';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';
import { VideoPlayerComponent } from '../../components/video-player/video-player.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare let jQuery: any

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.css']
})
export class WatchComponent implements OnInit {
  @ViewChild("appVideoPlayer") appVideoPlayer: VideoPlayerComponent;

  item :any

  constructor(
    private storage: StorageService,
    private router: Router, 
    private _location: Location
  ) { 
    let me = this;
    me.item = JSON.parse(storage.getItem("Episode"))
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });     
    jQuery("#pageTop").addClass("hide")
    jQuery("#scrolling").addClass("hide")
    jQuery("#hiddenToolBar").val(0)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)

    //.addClass("hide")

  }
  back(){
    jQuery("#pageTop").removeClass("hide")
    jQuery("#scrolling").removeClass("hide")
    this._location.back();
  }

  
  ngAfterViewInit(): void {
    const me = this
    jQuery("#pageTop").addClass("hide")
    jQuery("#scrolling").addClass("hide")
    jQuery("#hiddenToolBar").val(0)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)
    //#appVideoPlayer 

  }
  ngOnInit(): void {
    jQuery("#pageTop").addClass("hide")
    jQuery("#scrolling").addClass("hide")
    jQuery("#hiddenToolBar").val(0)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)    
  }
  goToSuscribe(timeLap:any){
    this.router.navigate(['/suscribe', "Full"])
    window.scrollTo(0, 0)
  }
  timeUpdate(timeLap:any){
    let me = this;

    if (timeLap.currentTime >= timeLap.duration) {
    }
  }

}
