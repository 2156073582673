<div class="title" mat-dialog-title>

    <!-- <mat-icon class="icon">vpn_key</mat-icon> -->

    <span *ngIf="!registrarte">Escribe tu correo y contraseña </span>

    <span *ngIf="registrarte">Suscribete con correo y contraseña </span>

</div>
<div mat-dialog-content>

    <!-- <div class="alert alert-success" *ngIf="error.length == 0">
        Escribe los datos con los que te registraste

    </div> -->

    <form [formGroup]="frmUser" autocomplete="off" novalidate (ngSubmit)="logIn()" fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-form-field style="width: 100%;">
            <mat-label>Correo</mat-label>
            <input matInput [(ngModel)]="usuario.email" type="email" name="email" [email]="true" formControlName="email">
        </mat-form-field>
        <mat-form-field style="width: 100%;" *ngIf="registrarte">
            <mat-label>Nombre</mat-label>
            <input matInput [(ngModel)]="usuario.nombre" type="text" name="nombre" formControlName="nombre">
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Contraseña</mat-label>
            <input matInput [(ngModel)]="usuario.password" type="password" name="password" formControlName="password">
        </mat-form-field>

    </form>

</div>
<div *ngIf="cargando">

    <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>
<div class="alert alert-success" *ngIf="successMessage.length >0 ">
    {{successMessage}}
</div>
<div class="alert alert-danger" *ngIf="error.length >0 ">
    {{error}}
</div>
<div *ngIf="!cargando">
    <mat-progress-bar></mat-progress-bar>
</div>

<div mat-dialog-actions align="end">

    <button mat-stroked-button color="accent" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
    <button mat-stroked-button color="accent" (click)="logIn()" [disabled]="!frmUser.valid" *ngIf="!registrarte">Acceder</button>
    <button mat-stroked-button color="accent" (click)="nuevoUsuario()" cdkFocusInitial *ngIf="registrarte">Suscribirse</button>
    <button mat-stroked-button color="accent" (click)="recuperar()" [disabled]="!frmUser.valid" style="float: right;" *ngIf="!registrarte">Recuperar Contraseña</button>
</div>