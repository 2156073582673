import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent  } from './pages/home/home.component';
import { PrivacyComponent  } from './pages/privacy/privacy.component';
import { SuscribeComponent  } from './pages/suscribe/suscribe.component';
import { CatalogoComponent  } from './pages/catalogo/catalogo.component';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AboutComponent } from './pages/about/about.component';
import { TerminosPage } from './pages/terminos/terminos.page';
import { AccederComponent } from './pages/acceder/acceder.component';
import { StorageService } from 'src/app/services/storage.services';
import { DetailComponent } from './pages/detail/detail.component';
import { WatchComponent } from './pages/watch/watch.component';
import { KidsEComponent } from './pages/kidse/kidse.component';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['privacy']);
const redirectLoggedInToSendEmail = () => redirectLoggedInTo(['send-email']);

// me.isUserLoggedIn = ;
  // {path: 'suscribe/:suscription_type' ,component: (() => {
  //     return localStorage.getItem("User") == null ? SuscribeComponent : redirectTo: '/suscribe/Pin';})() 
  //   }   

const routes: Routes = [
  {path: '',component: HomeComponent,},
  {path: 'home',component: HomeComponent,},
  {path: 'peliculas',component: HomeComponent,},
  {path: 'series',component: HomeComponent,},
  {path: 'descubre_y_aprende',component: HomeComponent,},
  {path: 'musica',component: HomeComponent,},
  {path: 'privacy',component: PrivacyComponent,},
  {path: 'about',component: AboutComponent,},
  {path: 'terminos',component: TerminosPage,},
  {path: 'activar',redirectTo: '/suscribe/Pin', pathMatch: 'full'},
  {path: 'suscribe/:suscription_type',component: SuscribeComponent,},
  {path: 'acceder',component: AccederComponent,},
  {path: 'browse/:type/:user',component: CatalogoComponent,},
  {path: 'browse/:type',component: CatalogoComponent,},
  // {path: 'kidse',component: KidsEComponent,},
  {path: 'detail/:item',component: DetailComponent,},
  {path: 'watch/:item:/:episode', component: WatchComponent},
  { path: '**', component: HomeComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
