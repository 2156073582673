
import { Component,  OnInit,Input  } from '@angular/core';
import { StorageService } from '../../services/storage.services';

import { DialogMediaItemComponent } from '../../dialogs/dialog-media-item/dialog-media-item.component'
import { DialogMusicItemComponent } from '../../dialogs/dialog-music-item/dialog-music-item.component'
import { DialogCraftItemComponent } from '../../dialogs/dialog-craft-item/dialog-craft-item.component'

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-card-media',
  templateUrl: './card-media.component.html',
  styleUrls: ['./card-media.component.css']
})
export class CardMediaComponent implements OnInit {

  @Input() item ;
  classCard='large';
  locked= true;
  constructor(
    public dialog: MatDialog,
    private storage: StorageService,
    private router: Router,
    private DataSrv: DataService,
    ) {
    var me = this;

  }
  ngOnInit() {
    let me = this;
    
    let GtSubscriptionType = localStorage.getItem("GtSubscriptionType") ?  localStorage.getItem("GtSubscriptionType") : "NULL";
    GtSubscriptionType = GtSubscriptionType.toLowerCase();
    this.locked  = !(GtSubscriptionType == 'full')   

    if(me.item.type == 'musicals'){
      me.classCard='middle';
    }
  }

  openDetail(): void {
    let me = this
    let media = me.item
    switch(media.type){
      case 'musicals':
      case 'crafts':
        
        let args = {
          "ClassName": "GtVideos_X_GtEpisode",
          "Action": "Get",
          "Id_GtEpisode": me.item.id,
          "Session" :  me.item.Session, //me.storage.getItem("Session") ,
          "db": "Prometeo"
        };
        me.DataSrv.executeForCRUD(args).subscribe(response => {
          media = response.data[0]
          
          media.freeTime = me.item.Free_Time 
          if (response.success) {
            me.storage.store("Episode", JSON.stringify(media) );
            this.router.navigate(['/watch', me.stringToSlug(media.title_Media), me.stringToSlug(media.title)]) 
          }
        });

        break;
      default:
        me.storage.store("Media", JSON.stringify(media) );
        this.router.navigate(['/detail', me.stringToSlug(media.title)])
        break;
    }
  }
  stringToSlug (str:string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  openDialogMedia(): void {
    let me = this
    let media = me.item
    var name = ''
    var email = ''
    var mediaData = {
      id: media.id,
      title: media.title,
      synopsis: media.synopsis,
      poster: media.poster,
    }

    let dialogRef

    switch(media.type){
      case 'musicals':
        dialogRef = this.dialog.open(DialogMusicItemComponent, {
          width: '700px',
          data: mediaData
        });
        break;
      case 'crafts':
        dialogRef = this.dialog.open(DialogCraftItemComponent, {
          width: '700px',
          data: mediaData
        });        
        break;
      default:
        dialogRef = this.dialog.open(DialogMediaItemComponent, {
          width: '700px',
          data: mediaData
        });
        break;
    }



    dialogRef.afterClosed().subscribe(result => {
      let me = this;
    
    });

  }
}