import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar'
import { AuthService } from '../../services/auth.service'
import { UsuarioModel } from 'src/app/models/usuario.model';
import { ReponseResult } from '../../models/reponse_result.model';


export interface DialogData {
  registrarte: boolean
}



@Component({
  selector: 'dialog-login',
  templateUrl: './dialog-login.component.html',
  styleUrls: ['./dialog-login.component.css'],
})
export class DialogLogInComponent implements OnInit {
  public frmUser: FormGroup;
  error = ''
  cargando = false;
  dataItem: {
    id: string;
    title: string;
    synopsis: string;
    poster: string;
    email: string;
    name: string;
    password: string;
  };
  usuario: UsuarioModel;
  registrarte = false
  successMessage = ''
  constructor(
    private DataSrv: DataService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogLogInComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private auth: AuthService
  ) {
    let me = this
    me.registrarte = data.registrarte

  }


  ngOnInit() {
    let me = this;

    me.usuario = new UsuarioModel();
    // me.usuario.email = "erikmr.mx@gmail.com";
    // me.usuario.password = "123456";
    
    me.usuario.email = "";
    me.usuario.password = "";    

    me.frmUser = new FormGroup({
      email: new FormControl(me.usuario.email, [Validators.required, Validators.email]),
      password: new FormControl(me.usuario.password, [Validators.required]),
      nombre: new FormControl(),
    });
  }

  logIn() {
    let me = this;
    me.cargando = true
    me.error = ''
    me.usuario.email = me.frmUser.controls['email'].value
    me.usuario.password = me.frmUser.controls['password'].value
    me.auth.logIn(me.usuario).then((response: ReponseResult) => {
      if (response.success) {
        let responseResult = {
          action: '1',
          session: response.data[0].Session
        }
        this.dialogRef.close(responseResult);
      } else {
        let message = response.errorMessage
        message = "Los datos no son correctos"
        me.registrarte = false
        if (response.errorMessage.indexOf("EMAIL_NOT_FOUND")> -1) {
          message = "No encontramos tu cuenta. Sucribete ahora"
          me.registrarte = true
        }
        if (response.errorMessage.indexOf("TOO_MANY_ATTEMPTS_TRY_LATER") > -1) {
          message = "Se ha sobrepasado el numero de intentos permitidos. "
          me.registrarte = false
        }        
        

        me.error = message
        //errorMessage: "EMAIL_NOT_FOUND"
        //errorMessage:"INVALID_PASSWORD"
        // "EMAIL_NOT_FOUND"
        let action = "Cerrar"
        this._snackBar.open(message, action, {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ['red-snackbar'],
        });

      }
      me.cargando = false
    })
    

  }


  nuevoUsuario() {
    let me = this;
    me.cargando = true
    me.error = ''
    me.usuario.email = me.frmUser.controls['email'].value
    me.usuario.password = me.frmUser.controls['password'].value
    me.usuario.nombre = me.frmUser.controls['nombre'].value
    me._snackBar.dismiss() 
    me.auth.nuevoUsuario(me.usuario).then((response: ReponseResult) => {
      if (response.success) {
        let responseResult = {
          action: '1',
          session: response.data[0].Session,
          user : response.data[0].User,
        }
        me.dialogRef.close(responseResult);
      } else {
        let message = response.errorMessage
        message = "Los datos no son correctos"
        
        if (response.data[0]["code"].indexOf("auth/email-already-in-use")> -1) {
          message = "El correo ya se encuentra registrado. Inicia sesión con la contraseña que sleccionaste"
          me.registrarte = false
        }
        if (response.errorMessage.indexOf("TOO_MANY_ATTEMPTS_TRY_LATER") > -1) {
          message = "Se ha sobrepasado el numero de intentos permitidos. "
        }                
        me.error = message
        //errorMessage: "EMAIL_NOT_FOUND"
        //errorMessage:"INVALID_PASSWORD"
        // "EMAIL_NOT_FOUND"
        let action = "Cerrar"
        me._snackBar.open(message, action, {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ['red-snackbar'],
        });

      }
      me.cargando = false
    })
    

  }


  recuperar() {
    let me = this;
    me.error = ''
    me.usuario.email = me.frmUser.controls['email'].value
    me.usuario.password = me.frmUser.controls['password'].value
    me.usuario.nombre = me.frmUser.controls['nombre'].value
    me._snackBar.dismiss() 
    me.auth.changePassword(me.usuario).then((response: ReponseResult) => {
      let action = "Cerrar"
      if(response.success){
        me.successMessage =" Te enviaremos por correo instrucciones para restablecer tu contraseña"

      }else{
        me.successMessage ="En caso de tener registrado tu correo, te enviaremos instrucciones para restablecer tu contraseña"
      }
      this._snackBar.open(me.successMessage, action, {
        duration: 10000,
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: ['green-snackbar'],
      });
      //this.dialogRef.close(response);      
    })
   
  }
  onNoClick(): void {
    let me = this;
    this.dialogRef.close("");
  }


}
