
import { Component, Input, AfterViewInit, ViewChild, ElementRef, EventEmitter, Output, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment'
// import * as firebase from 'firebase/app';
import { DataService } from 'src/app/services/data.service';

declare var jQuery: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
   @Input() isUserLoggedIn = false;
  //isUserLoggedIn = false;
  public activeLang = 'es';
  GtSubscriptionType = '';
  user =""
  personajeUrl = "https://global-toons.imgix.net/www/avatars/angy.png?h=100";
  constructor(
    private authenticationService: AuthService,
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private storage: StorageService,
    private translate: TranslateService,
    private DataSrv: DataService,
  ) {
      var me = this;
      this.translate.setDefaultLang(this.activeLang);
      me.user = this.rutaActiva.snapshot.params.user
      if( !me.user ){
        me.user = localStorage.getItem("User")
      }
      me.verificaUsuario();
      me.storage.changes.subscribe((response) => {
        // console.log("🚀 ~ file: header.component.ts ~ line 38 ~ HeaderComponent ~ me.storage.changes.subscribe ~ me.storage.changes.subscribe")
        if(response.key == 'User' ){
          me.isUserLoggedIn = response.value ? true: false
          //me.isUserLoggedIn = true;
          
          
        }
        me.user = localStorage.getItem("User")
        me.personajeUrl = `https://global-toons.imgix.net/www/avatars/${localStorage.getItem("Personaje") }.png?h=100` ;
        //console.log("🚀 ~ file: header.component.ts ~ line 49 ~ HeaderComponent ~ me.storage.changes.subscribe ~ me.personajeUrl", me.personajeUrl)
      })   
  }
 

  verificaUsuario(){
    var me = this;
    return
    // me.isUserLoggedIn = false;

    // me.user = this.rutaActiva.snapshot.params.user
    // if( !me.user ){
    //   me.user = localStorage.getItem("User")
    // }
    // // console.log( me.user)
    // me.isUserLoggedIn = false;
    // if(me.user){

    //   let args = {
    //     ClassName: 'GtSubscribers',
    //     Action: 'logIn',
    //     Guid: me.user,
    //     db: 'Prometeo',
    //   }
    //   me.DataSrv.executeForCRUD(args).subscribe((response) => {
        
    //     if (response.success) {
          
    //       me.storage.store("GtSubscriptionType", response.data[0].Nombre_GtSubscriptionType );
    //       me.storage.store("Session", response.data[0].Session );

    //       me.storage.store("User", response.data[0].Guid );                   
    //       me.storage.store("Email", response.data[0].Email );                   
    //       me.storage.store("Personaje", response.data[0].Personaje );
    //       me.personajeUrl = `https://global-toons.imgix.net/www/avatars/${response.data[0].Personaje }.png?h=100` ;
    //       me.isUserLoggedIn = true;
    //     }
      
    //   });        
    // }else{
    //   me.isUserLoggedIn = false;
    //   // console.log("SIN USUARIO")
    // }
  }
  logOut() {
    let me = this;
    window.location.href  = "https://www.globaltoons.tv"
    me.storage.clear("GtSubscriptionType");
    me.storage.clear("Session");
    me.storage.clear("User");
    me.storage.clear("Email");
    
    me.storage.clear("Media");
    me.storage.clear("Episode");
    me.storage.clear("Personaje");


  }
  goHome(){
    let me = this;
    this.router.navigate(['/home'])
  }  
  acceder(){
    let me = this;
    if(me.isUserLoggedIn){
      let url = "/browse/" + me.storage.getItem("Session")
      window.location.href= url;
      return true
    }
    this.router.navigate(['/acceder'])
  }
  suscribe(){
    let me = this;
    let url = "/suscribe/77b04042-7480-48a0-b5cc-8e36767e5a6c"
    if(me.isUserLoggedIn){
      url = "/browse/" + me.storage.getItem("Session")
    }
    window.location.href= url;
  }

  ngOnInit() {
    let me = this;
    me.isUserLoggedIn = false;

    //// console.log(me.isUserLoggedIn)
  }

  ngAfterViewInit() {
    let me = this;


    jQuery( document ).ready(function() {
      // console.log("🚀 ~ file: header.component.ts ~ line 130 ~ HeaderComponent ~ jQuery.ready ~ jQuery.ready")
      
      me.verificaUsuario()

    });
    /* ======== ALL DROPDOWN ON HOVER======== */
    if (jQuery(window).width() > 991) {
      jQuery('.navbar-expand-lg .navbar-nav .dropdown').hover(function () {
        jQuery(this).addClass('').find('.dropdown-menu').addClass('show');
      }, function () {
        jQuery(this).find('.dropdown-menu').removeClass('show');
      });
    }

    if (jQuery(window).width() > 767) {
      jQuery('.navbar-expand-md .navbar-nav .dropdown').hover(function () {
        jQuery(this).addClass('').find('.dropdown-menu').addClass('show');
      }, function () {
        jQuery(this).find('.dropdown-menu').removeClass('show');
      });
    }
    var header_area = jQuery('.header');
    var main_area = header_area.find('.navbar');
    var zero = 0;
    var navbarSticky = jQuery('.navbar-sticky');

    header_area.css('height', header_area.height());
    main_area.addClass('navbar-scrollUp');
    main_area.css({ 'opacity': '0' }).addClass('navbar-sticky');
    main_area.appendTo(jQuery('body')).animate({ 'opacity': 1 });


  }

  ngAfterViewChecked() {
    let me = this;
  }

  public cambiarLenguaje(lang) {
    this.activeLang  = lang
   
    this.activeLang = this.activeLang;
    this.translate.use(this.activeLang);
  }
  
}