<mat-card class="media-card" (click)="openDetail()">
  <mat-card-header></mat-card-header>
  <div class="divImage">
    <img
      class="img-card"
      mat-card-image
      [src]="item.poster"
      alt="{{ item.title }}"
    />
    <button type="button" class="button--play">
      <i class="fa fa-play" aria-hidden="true"></i>
      <i *ngIf="item.isLocked == 1" class="fa fa-lock red"></i>
    </button>
  </div>
  <mat-card-content class="sinopsys-card">
    <mat-card-title class="title-card">{{ item.title }}</mat-card-title>
    <!-- {{item.synopsis}} -->
  </mat-card-content>
  <!-- <mat-card-actions>
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
    </mat-card-actions> -->
</mat-card>
<hr />
<br />
