import { Component, Inject ,OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';

export interface DialogData {
  email: string;
  name: string;
  password: string
}



@Component({
  selector: 'dialog-email',
  templateUrl: './dialog-email.component.html',
  styleUrls: ['./dialog-email.component.css'],
})
export class DialogEmailComponent implements OnInit{

  public ownerForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
    }


  ngOnInit() {
    this.ownerForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),

    });
  }

  onNoClick(): void {
    this.dialogRef.close("");
  }
  createOwner(value){
  }

}
