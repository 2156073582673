
<div class="main-wrapper home-interiores">

    <h1>¿Quiénes somos?</h1>
    <div>
        Global Toons es la única plataforma para niños 100% segura, certificada con Kids Safe Seal, la única certificación en el mundo que asegura que los niños pueden navegar únicamente en la página, sin poder salir de ella y que todo el contenido es apto para
        ellos. Estamos disponibles en toda Latinoamérica, con excepción de Brasil; contamos con más de 300 horas de programas únicos de diferentes países del mundo. Aparte de tener series y películas, puedes encontrar libros, experimentos, manualidades,
        música y muchas cosas más.
    </div>

    <h1> Misión</h1>
    <div>
        Lucy y Alex tienen la misión de enseñarles a los niños a través de series y películas animadas, diferentes experiencias, variedad de culturas y mucha diversión. Es por eso, que para ellos, lo más importante es que los niños exploren y descubran diferentes
        aventuras en un ambiente 100% para su edad.

    </div>
    <h1> Visión</h1>
    <div>
        Global Toons se convertirá en una experiencia inolvidable para incontables niños. Es la marca que combina diversión, aprendizaje y seguridad. Hoy el mundo animado está a tu alcance, Global Toons, representado por sus personajes exclusivos, llevan un mensaje
        único de diversidad cultural, diversión y aprendizaje a todos los niños de LATAM. Global Toons es una plataforma 100% segura y simple para que los niños exploren el mundo a través de series y películas exclusivas en línea, música, libros y hasta
        aprenderán a construir un modelo de volcán o a cocinar

    </div>
    <br> Adolfo Prieto número 1213, Colonia Del Valle, Delegación Benito Juárez, Ciudad de México, C.P. 03100.

</div>
