
<div class="asbout container py-4" style="margin-top:150px">

    <div id="contenido" class="row justify-content-center ">


        <div class="col-lg-10">
            <div class=" bg-white text-center p-4">

                <div >
                    <div class="row justify-content-center py-3">
                        <div class="col-md-12">
                            <h1 class=" title-primary text--pink">Para disfrutar de las aventuras de la pandilla y sus amigos
                                <span class=" title-primary text--blue"><br>accede a tu cuenta.<br> </span>

                            </h1>
                        </div>
                    </div>
                    <div class="row py-3">
                        <div class="col-md-3 ">
                            <div class="opcione">
                                <img [hidden]="soyAdulto" src="https://global-toons.imgix.net/www/avatars/didi.png?h=300" >
                                <img [hidden]="!soyAdulto" src="https://global-toons.imgix.net/www/avatars/maca.png?h=300" >
                                
                            </div>
                        </div>
                        <div class="col-md-6 ">
                            <div class="opcion-acceder" [hidden]="soyAdulto" >
                                <h2 class="about--title-height title-primary text--pink">Para continuar, necesitas ser:</h2>
                                <button (click)="setSoyAdulto()" class="btn btn-dark btn-acceder" style="width: 150px; ">papá</button>&nbsp;&nbsp;
                                <button (click)="setSoyAdulto()" class="btn btn-dark btn-acceder" style="width: 150px; ">mamá</button>
                            </div>

                            <div [hidden]="!soyAdulto" class="opcion-login" style="padding: 15px;width: 100%;">
                                <!-- <h1 class=" title-primary text--pink"> Proporciona tu correo y contraseña</h1> -->
                                <form [formGroup]="frmUser" autocomplete="off" novalidate (ngSubmit)="logIn()" fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                                    <mat-form-field style="width: 75%;">
                                        <mat-label>Correo</mat-label>
                                        <input matInput [(ngModel)]="usuario.email" type="email" name="email" [email]="true" formControlName="email">
                                    </mat-form-field>

                                    <mat-form-field style="width: 75%;">
                                        <mat-label>Contraseña</mat-label>
                                        <input matInput [(ngModel)]="usuario.password" type="password" name="password" formControlName="password">
                                    </mat-form-field>

                                </form>

                                <div  class="row" >
                                    <div class="col-md-12 " style="text-align: right; ">
                                        <button (click)="suscribir()" mat-flat-button style="font-size: 10px;">Suscríbete</button>

                                        <button (click)="recuperar()" mat-flat-button style="font-size: 10px;">Recuperar Contraseña</button>
                                    </div>

                                </div>
                                <button class="btn btn-light button-login"  (click)="logIn()">Acceder</button><br><br>
                                <!-- <p class="text--blue" style="width: 100%;text-align: center; font-size: 10px;">Si no tienes cuenta </p>
                                <button class="btn btn-light  button-login" (click)="suscribir()" cdkFocusInitial>Suscríbete</button>  -->
                                <!-- <br><br> -->



                            </div>
                            <div style="margin: 15px;">
                                <div *ngIf="cargando">
                                    <mat-progress-bar mode="buffer"></mat-progress-bar>
                                </div>
                                <div class="alert alert-success" *ngIf="successMessage.length >0 ">
                                    {{successMessage}}
                                </div>
                                <div class="alert alert-danger" *ngIf="errorMessage.length >0 ">
                                    {{errorMessage}}
                                    <br><br>
                                    <p style="width: 100%;text-align: center; " [hidden]="ocultarContrasenia">

                                        <button (click)="recuperar()" mat-flat-button style="font-size: 10px;">Recuperar Contraseña</button><br>
                                    </p>
                                    <p style="width: 100%;text-align: center; " [hidden]="ocultarSuscribir">
                                        <button (click)="showPagoStripe()" mat-flat-button style="font-size: 10px;">Suscribete</button><br>
                                    </p>                                    
                                </div>

                            </div>
                        </div>

                        <div class="col-md-3 ">
                            <div class="opcione">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
 

            </div>
        </div>
        <div class="col-lg-2 logoBarra ">

            <br>
            <a href="https://appgallery.cloud.huawei.com/marketshare/app/C101017659?locale=es_US&source=appshare&subsource=C101017659">
                <img src="https://global-toons.imgix.net/logos/app-gallery-badge.png?w=150&H=100" alt="Huawei" />
            </a><br> <br>
            <a href="https://apps.apple.com/us/app/global-toons/id1497217074?ls=1">
                <img src="https://global-toons.imgix.net/logos/apple-store-badge.png?w=150&H=100" alt="Apple" />
            </a> <br> <br>
            <a href="https://play.google.com/store/apps/details?id=app.globaltoons.tv">
                <img src="https://global-toons.imgix.net/logos/google-play-badge.png?w=150&H=100" alt="Google" />
            </a><br> <br>
            <a href="https://www.kidsafeseal.com/certifiedproducts/globaltoons.html" target="_blank"> <img src="https://global-toons.imgix.net/logos/kidsafe.png?w=150&H=100" style="margin-top: 10px;" alt="Global Toons"></a>

        </div>

    </div>


</div>

