import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core'
import { ConstantesService } from '../../services/constantes.services'
import { DataService } from '../../services/data.service'
import { Router, ActivatedRoute ,NavigationEnd} from '@angular/router'
import { Meta } from '@angular/platform-browser'
// import { OwlOptions } from 'ngx-owl-carousel-o'
import { StorageService } from '../../services/storage.services';
import { TranslateService } from '@ngx-translate/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { DialogMediaItemComponent } from '../../dialogs/dialog-media-item/dialog-media-item.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { timer } from 'rxjs'

declare let jQuery: any

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit {
  public activeLang = 'es';

  // @ViewChild('rev_slider_custom_2') revSliderCustom2: ElementRef
  showHeader = false
  _showCharity = false
  _showNature = false
  hiddenVideo = false
  preHiddenVideo = false
  videoIntro = {
    title: 'Spot',
    synopsis: 'Intro',
    poster:
      'https://global-toons.imgix.net/assets/images-about.png?auto=compress',
    type: 'video/mp4',
    skipIntro: false,
    url: 'https://d293jkhqefsxxu.cloudfront.net/spot.mp4',
  }
  ctx1
  ctx2
  arrMedias = []
  iPersonaje = 0
  arrPersonajes = [
    {
      nombre: 'Lucy',
      sinopsis:
        'Su mamá es una aventurera y su papá un conocedor de la tecnología; así que Lucy heredó las dos cualidades más grandes de sus padres: le gusta estar sumergida en lo más nuevo de la tecnología sin perder su amor por las grandes aventuras. De esta manera, Lyucy, por nacimiento, es una aventurera tecnológica.',
    },
    {
      nombre: 'Didi',
      sinopsis:
        'Didi es uno de esos niños que siempre “anda en la luna”: soñando con viajar al espacio, descubriendo objetos y seres que nunca te imaginarías. Su más grande sueño es llevar a su perro Tomás al espacio con él, cuando sea grande. Lo más divertido de esta aventura espacial será que solo llevará chocolates de provisiones, porque es lo que más le gusta comer.',
    },
    {
      nombre: 'Alex',
      sinopsis:
        'Alex, como le pusó su abuela, es soñador por excelencia. Desde muy pequeño, se le ha podido ver incontables veces mirando al horizonte, en silencio y con una sonrisa en su cara. Cuando le preguntan en qué piensa, él responde con uno de sus tantos sueños: ser actor, escritor, campeón de hoverboard, cantante, astronauta, bombero, entre muchos otros.',
    },
    {
      nombre: 'Robertina',
      sinopsis:
        'Robertina es la niña más versátil del grupo de amigos: le gusta siempre estar a la moda y lucir ropas muy bonitas y coloridas y, al mismo tiempo, le encanta jugar en el lodo o en el suelo con cualquier mascota. Si, ¡Robertina es amante de los animales! De hecho, ha logrado rescatar a un par de cachorros con sus papás y eso la hace muy feliz.',
    },
  ]
  version = ''
  height = 400
  width = 200
  hideTheSlider = false
  sliderLive = true
  slider : any
  guid = ''

  // customOptions: OwlOptions = {
  //   loop: true,
  //   mouseDrag: true,
  //   touchDrag: true,
  //   pullDrag: false,
  //   dots: false,
  //   navSpeed: 300,
  //   navText: ['Anterior', 'Siguiente'],
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     400: {
  //       items: 3,
  //     },
  //     740: {
  //       items: 3,
  //     },
  //     940: {
  //       items: 4,
  //     },
  //   },
  //   nav: true,
  // }

  constructor(
    public dialog: MatDialog,
    private Constantes: ConstantesService,
    private DataSrv: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private _snackBar: MatSnackBar,
    private storage: StorageService,
    private translate: TranslateService
  ) {
    const me = this
    //this.translate.setDefaultLang(this.activeLang);
    me.version = Constantes.VER
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });   
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)      
    // me.isUserLoggedIn = me.storage.getItem("Session") != null;
    // me.storage.changes.subscribe((response) => {
    //   if(response.key == 'Session' ){
    //     me.isUserLoggedIn = response.value ? true: false
    //   }
    // })    
    
  }

  ngOnInit(): void {
    const me = this
    me.showHeader = true
  }

  ngAfterViewChecked(): void {
    const me = this
  }
  setPersonaje(index): void {
    this.iPersonaje = index
  }
  ngOnDestroy(){
  }
  ngAfterViewInit(): void {
    const me = this
    if( !me.slider) {

      // me.slider = jQuery(me.revSliderCustom2.nativeElement)
      //   .show()
      //   .revolution({
      //     delay: 5000,
      //     sliderLayout: 'fullwidth',
      //     sliderType: 'standard',
      //     responsiveLevels: [1171, 1025, 769, 480],
      //     gridwidth: [1171, 1025, 769, 480],
      //     gridheight: [555, 505, 455, 350],
      //     navigation: {
      //       arrows: {
      //         enable: true,
      //         style: 'hesperiden',
      //         hide_onleave: false,
      //       },
      //       bullets: {
      //         enable: false,
      //         style: 'hesperiden',
      //         hide_onleave: false,
      //         h_align: 'center',
      //         v_align: 'bottom',
      //         h_offset: 0,
      //         v_offset: 20,
      //         space: 15,
      //       },
      //     },
      //     disableProgressBar: 'on',
      //   })
      
    }
   

  }

  goToSuscribe(timeLap:any){
    this.router.navigate(['/suscribe', "Full"])
    window.scrollTo(0, 0)
  }  

  showCharity(): void {
    const me = this
    me._showCharity = !me._showCharity
  }
  showNature(): void {
    const me = this
    me._showNature = !me._showNature
  }

  saltarIntro(): void {
    const me = this
    me.hiddenVideo = true
  }

  timeUpdate(timeLap: any): void {
    const me = this

    if (timeLap.currentTime >= timeLap.duration) {
      const source1 = timer(2000, 3000)
      const source2 = timer(500, 1000)
      const a = source1.subscribe((val) => {
        me.preHiddenVideo = true
        const b = source2.subscribe((val) => {
          me.hiddenVideo = true
        })
      })
    }
  }

  openDialogMedia(media: any): void {
    const me = this

    const mediaData = {
      id: media.id,
      title: media.title,
      synopsis: media.synopsis,
      poster: media.poster,
    }
    const dialogRef = this.dialog.open(DialogMediaItemComponent, {
      width: '700px',
      data: mediaData,
    })

    dialogRef.afterClosed().subscribe((result) => {
      const me = this
    })
  }
  ngOnChanges() {
    // if (this.hideTheSlider === true && this.slider) { 
    //     // queries the conditional and if the 
    //     // slider already exists on the dom

    //     // RS's kill switch
    //     this.slider.revkill();

    //     // set the ngIf conditional for the html rewrite
    //     this.sliderLive = false;
    //     setTimeout(() => this.sliderLive = true, 100);
    // }
}

  acceder(): void {
    const me = this
    me.router.navigate(['/suscribe', 'ALeKk03FD6dQ4GPcx3Rjt0QBtW6lftKeQg'])
  }

}
