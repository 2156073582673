<div class="main-wrapper home-store" >



    <!-- ====================================
———	BOX SECTION
===================================== -->
    <section style="margin-top: 200px;" class="d-none d-sm-block section-top">
        <div class="container">
            <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
                <span class="shape shape-left bg-info"></span>
                <h2 class="text-danger">{{'Explora todo lo que tenemos para ti' | translate}} </h2>
                <span class="shape shape-right bg-info"></span>
            </div>
            <div class="row  fadeInUp">
                <div class="col-4 ">
                    <app-card-section titulo="{{'Series y películas' | translate}} " [cardName]="'series_y_peliculas'">
                    </app-card-section>
                </div>

                <div class="col-4 ">
                    <app-card-section titulo="{{'Música y karaoke' | translate}} " [cardName]="'musica_y_karaoke'">
                    </app-card-section>
                </div>

                <div class="col-4 ">
                    <app-card-section titulo="{{'Descubre y aprende' | translate}} " [cardName]="'descubre_y_aprende'">
                    </app-card-section>
                </div>
            </div>
            <br><br>
            <div class="row  fadeInUp">
                <div class="col-4 ">
                    <app-card-section titulo="{{'Recorre el mundo' | translate}} " [cardName]="'recorre_el_mundo'">
                    </app-card-section>
                </div>
                <div class="col-4 ">
                    <app-card-section titulo="{{'Libros ¡Muy pronto!' | translate}} " [cardName]="'libros'">
                    </app-card-section>
                </div>
                <div class="col-4 ">
                    <app-card-section titulo="{{'Juegos ¡Muy pronto!' | translate}} " [cardName]="'juegos'">
                    </app-card-section>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- Element Wrapper Ends -->