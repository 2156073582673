import { Injectable,Output, EventEmitter} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { UsuarioModel } from '../models/usuario.model'
import { ReponseResult } from '../models/reponse_result.model';
import { DataService } from './data.service';
// import { AngularFirestore } from '@angular/fire/firestore';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';

import { StorageService } from 'src/app/services/storage.services';

@Injectable({ providedIn: 'root'})
export class AuthService {

  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  // @Output() getLoggedIn: EventEmitter<any> = new EventEmitter();
  rr: ReponseResult = {
    errorMessage: "",
    successMessage: "",
    total: "0",
    success: false,
    data: []

  }
  //Crear Nuevo Usuario
  private url = 'https://identitytoolkit.googleapis.com/v1/accounts:';
  private apiKey = 'AIzaSyAYlogJjFOS82ydYt336YzEMjqH6Ej-vzY';
  //

  //https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=[API_KEY]

  //login
  //https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=[API_KEY]


  constructor(
    // firestore: AngularFirestore,
    private DataSrv: DataService,
    private http: HttpClient,
    private storage: StorageService,
  ) { }

  logOut() {

  }

  logIn(usuario: UsuarioModel) {
    let me = this;
    me.storage.clear("Session");
    me.storage.clear("User");
    me.rr =   {
      errorMessage: "",
      successMessage: "",
      total: "0",
      success: false,
      data: []
  
    }
    const authData = {
      ...usuario,
      returnSecureToken: true
    }

    return new Promise<any>((resolve, reject) => {

      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {

          firebase.auth().signInWithEmailAndPassword(usuario.email, usuario.password)
            .then((resFirebase: any) => {
              let args = {
                "ClassName": "GtSubscribers",
                "Action": "logIn",
                "Email": usuario.email,
                "Password": usuario.email,
                "db": "Prometeo"
              };
              me.DataSrv.executeForCRUD(args).subscribe((resGt: any) => {
                if (resGt.success) {
      
                  me.isUserLoggedIn = new BehaviorSubject<boolean>(true);
                  me.storage.store("Session", resGt.data[0].Session );
                  me.storage.store("User", resGt.data[0].user );
                  me.storage.store("Email", resGt.data[0].Email );
                  me.storage.store("GtSubscriptionType", resGt.data[0].Suscripcion );      
                } 
                resolve(resGt);
              });

              //resolve(res);
            }, (err) => {
              let error = err
              me.rr.success = false
              if (error) {
                me.rr.data.push(error)
              }
              me.rr.errorMessage = error.message
              resolve(me.rr);
              // reject(err)
            }).catch(function (error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
            });
    
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });




    })


  }

  getSesion(usuario:string) {
    let me = this;
    return new Promise<any>((resolve, reject) => {
      let args = {
        "ClassName": "GtVerifica",
        "Action": "Suscripcion",
        "Usuario": usuario,
        "db": "Prometeo"
      };
      me.DataSrv.executeForCRUD(args).subscribe((response: any) => {
        resolve(response);

      });

    })
  }

  verificaSuscripcionByEmail(email:string) {
    let me = this;
    return new Promise<any>((resolve, reject) => {
      let args = {
        "ClassName": "GtVerifica_Suscripcion",
        "Action": "By_Email",
        "Email": email,
        "db": "Prometeo"
      };

      me.DataSrv.executeForCRUD(args).subscribe((response: any) => {
        resolve(response);

      });

    })
  }

  nuevoUsuario(usuario: UsuarioModel) {
    let me = this;

    return new Promise((resolve, reject) => {

      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {

          firebase.auth().createUserWithEmailAndPassword(usuario.email, usuario.password)
            .then((resFirebase: any) => {
              try{
                let args = {
                  "ClassName": "GtSubscribers",
                  "Action": "Registrer",
                  "Email": usuario.email,
                  "Nombre": usuario.nombre,
                  "Password": usuario.email,
                  "db": "Prometeo"
                };
  
                me.DataSrv.executeForCRUD(args).subscribe((resGt: any) => {
                  me.rr = resGt
                  me.isUserLoggedIn = new BehaviorSubject<boolean>(true);
                  me.storage.store("Session", resGt.data[0].Session );
                  me.storage.store("User", resGt.data[0].user );
                  me.storage.store("Email", resGt.data[0].Email );        
                  me.storage.store("GtSubscriptionType", resGt.data[0].Suscripcion );        
                            
                  resolve(me.rr);                
                });
              }
              catch(error){
                me.rr.success = false
                me.rr.data = []
                me.rr.errorMessage = error
                resolve(me.rr);                  
              }


            }, (error) => {
              var errorCode = error.code;
              var errorMessage = error.message;
              switch(errorCode){
                case 'auth/email-already-in-use':
                  break;

              }
                            
              me.rr.success = false
              me.rr.data.push(error)
              let index = me.rr.data.length  -1
              me.rr.errorMessage = me.rr.data[index].message
              resolve(me.rr);              
          })
          .catch(function (error) {
              var errorCode = error.code;
              var errorMessage = error.message;
            });
    
        }) // firebase.auth().setPersistence
        // .catch(function (error) {
        //   var errorCode = error.code;
        //   var errorMessage = error.message;
        // });
    })
  }

  changePassword(usuario: UsuarioModel) {
    let me = this;
    const authData = {
      ...usuario,
      requestType: "PASSWORD_RESET"
    }
    //https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=[API_KEY]
    return new Promise((resolve, reject) => {
      me.procesarRequest(authData, "sendOobCode")
        .subscribe((resFirebase: any) => {
          me.rr.success = true
          me.rr.total = "1"
          me.rr.data.push(resFirebase)
          resolve(me.rr);

        }, (err) => {
          me.rr.success = false
          me.rr.data.push(err.error.error)
          me.rr.errorMessage = me.rr.data[0].message
          resolve(me.rr);
        })
    })

  }

  procesarRequest(authData: any, metodo: string) {
    return this.http.post(
      `${this.url + metodo}?key=${this.apiKey}`,
      authData,
    );
  }
}
