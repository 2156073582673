import { Component, OnInit, Input } from '@angular/core'
import { StorageService } from '../../services/storage.services'
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';
@Component({
  selector: 'app-card-section',
  templateUrl: './card-section.component.html',
  styleUrls: ['./card-section.component.css'],
})
export class CardSectionComponent implements OnInit {
  @Input() cardName = 'series'
  @Input() Session = ''
  @Input() titulo = 'Series y Películas'
  class_bg = 'bg-verde'
  class_border = 'border-verde'
  //titulo = 'Series y Películas'
  icono = 'botonesporseccion_iconomusica.png'
  imageServer = 'https://global-toons.imgix.net/www/botones/'
  imagePath = this.imageServer + 'botonesporseccion_iconomusica.png'
  guid = ''
  

  constructor(
    private router: Router,
    private storage: StorageService
    ) {
      const me = this
      //me.Session = localStorage.getItem("Session") 
      // console.log(me.Session)


    }
    
  ngOnInit() {
    const me = this
    // console.log(me.Session)
    switch (me.cardName) {
      case 'series_y_peliculas':
        me.class_bg = 'bg-verde'
        me.class_border = 'border-verde'
        me.icono = 'botonesporseccion_iconoseriesypeliculas.png'
        break
      case 'musica_y_karaoke':
        me.class_bg = 'bg-amarillo'
        me.class_border = 'border-amarillo'
        me.icono = 'botonesporseccion_iconomusica.png'
        break
      case 'descubre_y_aprende':
        me.class_bg = 'bg-morado'
        me.class_border = 'border-morado'
        me.icono = 'botonesporseccion_iconodescubreyaprende.png'
        break
      case 'recorre_el_mundo':
        me.class_bg = 'bg-naranja'
        me.class_border = 'border-naranja'
        me.icono = 'botonesporseccion_iconorecorreelmundo.png'
        break                
      case 'libros':
        me.class_bg = 'bg-rojo'
        me.class_border = 'border-rojo'
        me.icono = 'botonesporseccion_iconolibros.png'
        break


      case 'juegos':
        me.class_bg = 'bg-azul-claro'
        me.class_border = 'border-azul-claro'
        // me.titulo = 'Juegos'
        me.icono = 'botonesporseccion_iconojuegos.png'
        break
    }
    this.imagePath = this.imageServer + me.icono
  }
}
