
import { Component, Input, AfterViewInit, ViewChild, ElementRef,EventEmitter,Output  } from '@angular/core';


interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitCancelFullScreen?: () => void;
  webkitFullscreenElement?: () => void;
}
interface TimeLap {
  currentTime: number;
  duration: number;
}
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent {
  @Input() videoToPlay: any;
  @Output() timeUpdate = new EventEmitter<TimeLap>();
  @Output() goToSuscribe = new EventEmitter<TimeLap>();

  @ViewChild('videoContainer') videoContainerEle: ElementRef;
  @ViewChild('videoPlayer') videoPlayerEle: ElementRef;
  @ViewChild('videoControls') videoControlsEle: ElementRef;

  @ViewChild('playpause') playpauseEle: ElementRef;
  @ViewChild('stop') stopEle: ElementRef;
  @ViewChild('mute') muteEle: ElementRef;
  @ViewChild('volinc') volincEle: ElementRef;
  @ViewChild('voldec') voldecEle: ElementRef;
  @ViewChild('progressDiv') progressDivEle: ElementRef;
  @ViewChild('progressBar') progressBarEle: ElementRef;
  @ViewChild('fs') fullscreenEle: ElementRef;
  @ViewChild('subtitles') subtitlesEle: ElementRef;

  public video;

  videoContainer;
 
  videoControls;
  playpause;
  stop;
  mute;
  volinc;
  voldec;
  progressDiv;
  progressBar;
  fullscreen;
  subtitles;
  srcSpot = 'https://d293jkhqefsxxu.cloudfront.net/video_emergente.mp4'
  index = 0
  isUserLoggedIn = false
  pausado = false
  GtSubscriptionType = "Basico"
  constructor() {
    var me = this;
    me.GtSubscriptionType = localStorage.getItem("GtSubscriptionType")
  }

  setTimeUpdate(currentTime , duration, freeTime){
    let me = this
    if(!me.GtSubscriptionType || me.GtSubscriptionType == 'undefined') me.GtSubscriptionType = "Basico"
    // if(me.video.src == me.srcSpot) return
    
    const TimeLap: TimeLap = {
      currentTime: currentTime,
      duration: duration

    };
    this.timeUpdate.emit(TimeLap);
    // console.log(currentTime)
    // console.log(freeTime)
    // console.log(me.GtSubscriptionType)
    // console.log(me.isUserLoggedIn)
    if(isNaN(freeTime)) freeTime = 10
    if(!me.isUserLoggedIn || me.GtSubscriptionType == "Basico"){
  
      if ((currentTime >= freeTime +20 )   ) {
        me.video.pause();
        me.video.src = me.srcSpot
        me.video.style="cursor: pointer;"
        me.video.addEventListener('click', function (e) {
          me.goToSuscribe.emit(TimeLap);
        });
  
        me.video.play();
      }
    }
    // if (isNaN(freeTime) ) freeTime = 0




  }


  public setVideoToPlay(newVideoPlay){
    let me = this;
    me.videoToPlay  = newVideoPlay;
    if(me.video.src.indexOf("video_emergente") > 0 ){
     return
    }
    me.play()
  }

  play() {
    let me = this;
    me.video.src = me.videoToPlay.url
    //me.video.poster = me.videoToPlay.poster+ '&bg=FFFFFF'
    if(me.videoToPlay.skipIntro){
      me.video.currentTime = me.videoToPlay.timeIntro ? me.videoToPlay.timeIntro:10;
    }
    me.video.play();
    

  }

  // Check the volume
  checkVolume(dir) {
    let me = this;
    if (dir) {
      var currentVolume = Math.floor(me.video.volume * 10) / 10;
      if (dir === '+') {
        if (currentVolume < 1) me.video.volume += 0.1;
      }
      else if (dir === '-') {
        if (currentVolume > 0) me.video.volume -= 0.1;
      }
      // If the volume has been turned off, also set it as muted
      // Note: can only do this with the custom control set as when the 'volumechange' event is raised, there is no way to know if it was via a volume or a mute change
      if (currentVolume <= 0) me.video.muted = true;
      else me.video.muted = false;
    }
    me.changeButtonState('mute');
  }

  // Change the volume
  alterVolume(dir) {
    let me = this;
    this.checkVolume(dir);
  }

  // Set the video container's fullscreen state
  setFullscreenData(state) {
    let me = this;
    me.videoContainer.setAttribute('data-fullscreen', !!state);
    // Set the fullscreen button's 'data-state' which allows the correct button image to be set via CSS
    me.fullscreen.setAttribute('data-state', !!state ? 'cancel-fullscreen' : 'go-fullscreen');

  }

  // Checks if the document is currently in fullscreen mode
  isFullScreen() {
    const fsDoc = <FsDocument>document;
    return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
    //!!(fsDoc.fullScreen || fsDoc.webkitIsFullScreen || fsDoc.mozFullScreen || fsDoc.msFullscreenElement || fsDoc.fullscreenElement);
  }

  // Changes the button state of certain button's so the correct visuals can be displayed with CSS
  changeButtonState(type) {
    let me = this;
    // Play/Pause button
    if (type == 'playpause') {
      if (me.video.paused || me.video.ended) {
        me.playpause.setAttribute('data-state', 'play');
      }
      else {
        me.playpause.setAttribute('data-state', 'pause');
      }
    }
    // Mute button
    else if (type == 'mute') {
      me.mute.setAttribute('data-state', me.video.muted ? 'unmute' : 'mute');
    }
  }

  // Fullscreen
  handleFullscreen() {
    let me = this;
    const fsDoc = <FsDocument>document;
    // If fullscreen mode is active...	
    me.video.controls = true;
    if (me.isFullScreen()) {
      // ...exit fullscreen mode
      // (Note: this can only be called on document)
      if (fsDoc.exitFullscreen) fsDoc.exitFullscreen();
      else if (fsDoc.mozCancelFullScreen) fsDoc.mozCancelFullScreen();
      else if (fsDoc.webkitCancelFullScreen) fsDoc.webkitCancelFullScreen();
      else if (fsDoc.msExitFullscreen) fsDoc.msExitFullscreen();
      me.setFullscreenData(false);
     
    }
    else {
      // ...otherwise enter fullscreen mode
      // (Note: can be called on document, but here the specific element is used as it will also ensure that the element's children, e.g. the custom controls, go fullscreen also)
      if (me.videoContainer.requestFullscreen) me.videoContainer.requestFullscreen();
      else if (me.videoContainer.mozRequestFullScreen) me.videoContainer.mozRequestFullScreen();
      else if (me.videoContainer.webkitRequestFullScreen) {
        // Safari 5.1 only allows proper fullscreen on the video element. This also works fine on other WebKit browsers as the following CSS (set in styles.css) hides the default controls that appear again, and 
        // ensures that our custom controls are visible:
        // figure[data-fullscreen=true] video::-webkit-media-controls { display:none !important; }
        //figure[data-fullscreen=true].controls { z-index:2147483647; }
        me.video.webkitRequestFullScreen();
      }
      else if (me.videoContainer.msRequestFullscreen) me.videoContainer.msRequestFullscreen();
      me.setFullscreenData(true);
    }
  }

  ngAfterViewChecked() {
    const me = this

    me.isUserLoggedIn = localStorage.getItem('Session') != null
  }
  ngAfterViewInit() {
    let me = this;
    var supportsVideo = !!document.createElement('video').canPlayType;
    if (supportsVideo) {
      // Obtain handles to main elements
      me.videoContainer = me.videoContainerEle.nativeElement //document.getElementById('videoContainer');
      me.video = me.videoPlayerEle.nativeElement// cument.getElementById('video');
      me.videoControls = me.videoControlsEle.nativeElement //document.getElementById('video-controls');

      //Hide the default controls
      me.video.controls = false;

      // Display the user defined video controls
      me.videoControls.setAttribute('data-state', 'visible');

      // Obtain handles to buttons and other elements
      me.playpause = me.playpauseEle.nativeElement  //document.getElementById('playpause');
      me.stop = me.stopEle.nativeElement  //document.getElementById('stop');
      me.mute = me.muteEle.nativeElement  //document.getElementById('mute');
      me.volinc = me.volincEle.nativeElement  //document.getElementById('volinc');
      me.voldec = me.voldecEle.nativeElement  //document.getElementById('voldec');
      me.progressDiv = me.progressDivEle.nativeElement  //document.getElementById('progress');
      me.progressBar = me.progressBarEle.nativeElement  //document.getElementById('progress-bar');
      me.fullscreen = me.fullscreenEle.nativeElement  //document.getElementById('fs');
      me.subtitles = me.subtitlesEle.nativeElement  //document.getElementById('subtitles');

      // If the browser doesn't support the progress element, set its state for some different styling
      var supportsProgress = true //= (document.createElement('progress').max !== undefined);
      if (!supportsProgress) me.progressDiv.setAttribute('data-state', 'fake');

      // Check if the browser supports the Fullscreen API
      var fullScreenEnabled = true //!!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled || document.createElement('video').webkitRequestFullScreen);
      // If the browser doesn't support the Fulscreen API then hide the fullscreen button
      if (!fullScreenEnabled) {
        me.fullscreen.style.display = 'none';
      }

      // Only add the events if addEventListener is supported (IE8 and less don't support it, but that will use Flash anyway)
      if (document.addEventListener) {
        // Wait for the video's meta data to be loaded, then set the progress bar's max value to the duration of the video
        me.video.addEventListener('loadedmetadata', function () {
          me.progressDiv.setAttribute('max', me.video.duration);
        });

        // Add event listeners for video specific events
        me.video.addEventListener('play', function () {
          me.changeButtonState('playpause');
        }, false);
        me.video.addEventListener('pause', function () {
          me.changeButtonState('playpause');
        }, false);
        me.video.addEventListener('volumechange', function () {
          me.checkVolume('');
        }, false);

        // Add events for all buttons			
        me.playpause.addEventListener('click', function (e) {
          if (me.video.paused || me.video.ended) me.video.play();
          else me.video.pause();
        });

        // Turn off all subtitles
        for (var i = 0; i < me.video.textTracks.length; i++) {
          me.video.textTracks[i].mode = 'hidden';
        }

        // // Creates and returns a menu item for the subtitles language menu
        // var subtitleMenuButtons = [];
        // var createMenuItem = function(id, lang, label) {
        //   var listItem = document.createElement('li');
        //   var button = listItem.appendChild(document.createElement('button'));
        //   button.setAttribute('id', id);
        //   button.className = 'subtitles-button';
        //   if (lang.length > 0) button.setAttribute('lang', lang);
        //   button.value = label;
        //   button.setAttribute('data-state', 'inactive');
        //   button.appendChild(document.createTextNode(label));
        //   button.addEventListener('click', function(e) {
        //     // Set all buttons to inactive
        //     subtitleMenuButtons.map(function(v, i, a) {
        //       subtitleMenuButtons[i].setAttribute('data-state', 'inactive');
        //     });
        //     // Find the language to activate
        //     var lang = this.getAttribute('lang');
        //     for (var i = 0; i < me.video.textTracks.length; i++) {
        //       // For the 'subtitles-off' button, the first condition will never match so all will subtitles be turned off
        //       if (me.video.textTracks[i].language == lang) {
        //         me.video.textTracks[i].mode = 'showing';
        //         this.setAttribute('data-state', 'active');
        //       }
        //       else {
        //         me.video.textTracks[i].mode = 'hidden';
        //       }
        //     }
        //     subtitlesMenu.style.display = 'none';
        //   });
        //   subtitleMenuButtons.push(button);
        //   return listItem;
        // }
        // // Go through each one and build a small clickable list, and when each item is clicked on, set its mode to be "showing" and the others to be "hidden"
        // var subtitlesMenu;
        // if (me.video.textTracks) {
        //   var df = document.createDocumentFragment();
        //   subtitlesMenu = df.appendChild(document.createElement('ul'));
        //   subtitlesMenu.className = 'subtitles-menu';
        //   subtitlesMenu.appendChild(createMenuItem('subtitles-off', '', 'Off'));
        //   for (var i = 0; i < me.video.textTracks.length; i++) {
        //     subtitlesMenu.appendChild(createMenuItem('subtitles-' + me.video.textTracks[i].language, me.video.textTracks[i].language, me.video.textTracks[i].label));
        //   }
        //   me.videoContainer.appendChild(subtitlesMenu);
        // }
        // me.subtitles.addEventListener('click', function(e) {
        //   if (subtitlesMenu) {
        //     subtitlesMenu.style.display = (subtitlesMenu.style.display == 'block' ? 'none' : 'block');
        //   }
        // });

        // The Media API has no 'stop()' function, so pause the video and reset its time and the progress bar
        me.stop.addEventListener('click', function (e) {
          me.video.pause();
          me.video.currentTime = 0;
          me.progressDiv.value = 0;
          // Update the play/pause button's 'data-state' which allows the correct button image to be set via CSS
          me.changeButtonState('playpause');
        });
        me.mute.addEventListener('click', function (e) {
          me.video.muted = !me.video.muted;
          me.changeButtonState('mute');
        });
        me.volinc.addEventListener('click', function (e) {
          me.alterVolume('+');
        });
        me.voldec.addEventListener('click', function (e) {
          me.alterVolume('-');
        });
        me.fullscreen.addEventListener('click', function (e) {
          me.handleFullscreen();
        });

        // As the video is playing, update the progress bar
        me.video.addEventListener('timeupdate', function () {
          // For mobile browsers, ensure that the progress element's max attribute is set
          if (!me.progressDiv.getAttribute('max')) me.progressDiv.setAttribute('max', me.video.duration);
          me.progressDiv.value = me.video.currentTime;
          me.progressDiv.style.width = Math.floor((me.video.currentTime / me.video.duration) * 100) + '%';
          let freeTime = me.video.duration * ( me.videoToPlay.freeTime /100 ) 
          if(me.video.src.indexOf("video_emergente") > 0 ){
            freeTime = 100
          }
          me.setTimeUpdate(me.video.currentTime,me.video.duration,freeTime)




        });

        // React to the user clicking within the progress bar
        me.progressDiv.addEventListener('click', function (e) {
          // Also need to take the parents into account here as .controls and figure now have position:relative
          var pos = (e.pageX - (this.offsetLeft + this.offsetParent.offsetLeft + this.offsetParent.offsetParent.offsetLeft)) / this.offsetWidth;
          me.video.currentTime = pos * me.video.duration;
          
        });

        // Listen for fullscreen change events (from other controls, e.g. right clicking on the video itself)
        document.addEventListener('fullscreenchange', function (e) {
          me.setFullscreenData(false) //(!!(document.fullScreen || document.fullscreenElement));
        });
        document.addEventListener('webkitfullscreenchange', function () {
          me.setFullscreenData(true) //(!!document.webkitIsFullScreen);
        });
        document.addEventListener('mozfullscreenchange', function () {
          me.setFullscreenData(false) //(!!document.mozFullScreen);
        });
        document.addEventListener('msfullscreenchange', function () {
          me.setFullscreenData(false) //(!!document.msFullscreenElement);
        });
      }
      
      
      me.play()
    
    }

  }



}
