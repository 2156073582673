<div class="title" mat-dialog-title>
    <span> Escribe el PIN de tu tarjeta </span>
</div>
<div mat-dialog-contentx>
    <form [formGroup]="ownerForm" autocomplete="off" novalidate (ngSubmit)="addCodigo()" fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-form-field style="width: 100%;">
            <mat-label>PIN </mat-label>
            <input matInput [(ngModel)]="data.codigo" type="text" name="codigo" formControlName="codigo">
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
    <button mat-stroked-button color="accent" (click)="addCodigo()" [disabled]="!ownerForm.valid">Usar PIN</button>

</div>