import { Component, OnInit, ViewChild } from '@angular/core'
import { ConstantesService } from '../../services/constantes.services'
import { DataService } from '../../services/data.service'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { Meta } from '@angular/platform-browser'
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel'
import { OwlModule } from 'ngx-owl-carousel'
import { MatCardModule } from '@angular/material/card'
import { MatToolbarModule } from '@angular/material/toolbar'
import { DialogMediaItemComponent } from '../../dialogs/dialog-media-item/dialog-media-item.component'
import { DialogMusicItemComponent } from '../../dialogs/dialog-music-item/dialog-music-item.component'
import { DialogCraftItemComponent } from '../../dialogs/dialog-craft-item/dialog-craft-item.component'
import { StorageService } from 'src/app/services/storage.services';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

export interface Tile {
  color: string
  cols: number
  rows: number
  text: string
}
declare let jQuery: any
declare let fbq: any
@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css'],
})
export class CatalogoComponent implements OnInit {
  txtBuscar = ''
  class_bg = 'bg-verde'
  showHeader = false
  version = ''
  arrMedias = []
  arrMovies = []
  arrMoviesAndShows = []
  arrMusicals = []
  arrCrafts = []
  arrToPrint = []
  colorSeccion = 'bg-verde'
  tiles: Tile[] = [
    { text: 'One', cols: 3, rows: 1, color: 'lightblue' },
    { text: 'Two', cols: 1, rows: 2, color: 'lightgreen' },
    { text: 'Three', cols: 1, rows: 1, color: 'lightpink' },
    { text: 'Four', cols: 2, rows: 1, color: '#DDBDF1' },
  ]

  mySlideOptions = {
    items: 7,
    dots: false,
    nav: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: true,
    animateOut: 'slideOutDown',
    animateIn: 'flipInX',
  }
  myCarouselOptions = { items: 3, dots: true, nav: true }
  Session = ""
  constructor(
    public dialog: MatDialog,
    private rutaActiva: ActivatedRoute,
    private Constantes: ConstantesService,
    private DataSrv: DataService,
    private router: Router,
    private meta: Meta,
    private _snackBar: MatSnackBar,
    private storage: StorageService,
  ) {
    const me = this

    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)   
    fbq('track', 'ViewContent');
    me.version = Constantes.VER
    

    let type = this.rutaActiva.snapshot.params.type
    let user = this.rutaActiva.snapshot.params.user
    if(!type) type="series" 
    if( !user ){
      user = localStorage.getItem("User")
    }
  

    if(user){
    // me.storage.clear("GtSubscriptionType");
    // me.storage.clear("Session");
    // me.storage.clear("User");
    // me.storage.clear("Email");
    
    // me.storage.clear("Media");
    // me.storage.clear("Episode");              
      let args = {
        ClassName: 'GtSubscribers',
        Action: 'logIn',
        Guid: user,
        db: 'Prometeo',
      }
      me.DataSrv.executeForCRUD(args).subscribe((response) => {
        
        if (response.success) {
          me.storage.store("GtSubscriptionType", response.data[0].Nombre_GtSubscriptionType );
          me.storage.store("Session", response.data[0].Session );
          me.storage.store("User", response.data[0].Guid );                   
          me.storage.store("Email", response.data[0].Email );                   
          me.storage.store("Personaje", response.data[0].Personaje );
        }

        const keywords: string[] = ['foo', 'bar', 'poo']
        this.meta.addTag({ name: 'keywords', content: keywords.join(',') })

       
        me.Session = response.data[0].Session

        const args = {
          ClassName: 'GtCatalogo_By_Session_V2',
          Action: 'Get',
          Session: response.data[0].Session,
          db: 'Prometeo',
        }
        me.DataSrv.executeForCRUD(args).subscribe((response) => {
          
          if (response.success) {
            me.arrMedias = response.data
            me.arrMovies = me.arrMedias.filter((e) => {
              return e.type == 'movies'
            })
            me.arrMoviesAndShows = me.arrMedias.filter((e) => {
              return e.type == 'movies' || e.type == 'shows'
            })
            me.arrMusicals = me.arrMedias.filter((e) => {
              return e.type == 'musicals'
            })
            me.arrCrafts = me.arrMedias.filter((e) => {
              return e.type == 'crafts'
            })
    
            switch (type) {
              case 'series_y_peliculas':
                this.showSeries()
                break
              case 'musica_y_karaoke':
                this.showMusic()
                break
              case 'descubre_y_aprende':
                this.showCrafts()
                break            
              case 'recorre_el_mundo':
                this.showRecorre() 
                break
              case 'libros':
                break
              case 'juegos':
                break
            }
    
    
            
    
          } else {
            me._snackBar.open(
              'La sesión expiro. necesitas loguearte nuevamente',
              'Aceptar',
              {
                duration: 10000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['red-snackbar'],
              }
            )
            //me.router.navigate(['/suscribe', guid])
          }
        })

      });        
    }else{
      console.log("SIN USUARIO")
      window.location.href  = "https://www.globaltoons.tv"
    }

    


    

  }
  showSeries() {
    const me = this
    me.colorSeccion = 'bg-verde'
    me.arrToPrint = []
    me.arrToPrint = me.arrMoviesAndShows
  }
  showMusic() {
    const me = this
    me.colorSeccion = 'bg-amarillo'
    me.arrToPrint = []
    me.arrToPrint = me.arrMusicals
  }
  showRecorre() {
    const me = this
    me.colorSeccion = 'bg-naranja'
    me.arrToPrint = []
    me.arrToPrint = me.arrCrafts
  }
  showCrafts() {
    const me = this
    me.colorSeccion = 'bg-morado'
    me.arrToPrint = []
    me.arrToPrint = me.arrCrafts
  }
  showBooks() {
    const me = this
    me.colorSeccion = 'bg-rojo'
    me.arrToPrint = []
    me.arrToPrint = me.arrMoviesAndShows
  }

  ngOnInit() {
    const me = this
    me.showHeader = true
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)        
  }
  ngAfterViewChecked() {
    const me = this
   
  }
}
