
import { Component,Input, AfterViewInit, ViewChild, ElementRef,EventEmitter,Output  } from '@angular/core';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.page.html',
  styleUrls: ['./terminos.page.css']
})
export class TerminosPage {
 
  constructor() {
    var me = this;
  }
  ngAfterViewInit() {
    let me = this;
  
  }
}
