<br /><br />

<div class="content">
  <div class="row">
    <div
      class="col-md-12 text-md-center d-flex flex-column flex-md-row mx-auto justify-content-center"
    >
      <a
        class="mb-2 mb-sm-0 mx-auto mx-md-0"
        href="https://appgallery.cloud.huawei.com/marketshare/app/C101017659?locale=es_US&source=appshare&subsource=C101017659 "
      >
        <img
          src="https://global-toons.imgix.net/logos/app-gallery-badge.png?w=150&H=100 "
          alt="Huawei "
        />
      </a>
      <a
        class="mb-2 mb-sm-0 mx-auto mx-md-0"
        href="https://apps.apple.com/us/app/global-toons/id1497217074?ls=1 "
      >
        <img
          src="https://global-toons.imgix.net/logos/apple-store-badge.png?w=150&H=100 "
          alt="Apple "
        />
      </a>
      <a
        class="mb-2 mb-sm-0 mx-auto mx-md-0"
        href="https://play.google.com/store/apps/details?id=app.globaltoons.tv "
      >
        <img
          src="https://global-toons.imgix.net/logos/google-play-badge.png?w=150&H=100 "
          alt="Google "
        />
      </a>
      <a
        class="mb-2 mb-sm-0 mx-auto mx-md-0"
        href="https://www.kidsafeseal.com/certifiedproducts/globaltoons.html "
        target="_blank "
      >
        <img
          src="https://global-toons.imgix.net/logos/kidsafe.png?w=130 "
          alt="KidSafe "
      /></a>
    </div>
  </div>
  <br /><br />
  <div
    class="row justify-content-center"
    style="background-image: url(assets/img/banner/slider-2/bg-1.png)"
  >
    <section class="d-none d-sm-block">
      <br /><br />
      <br /><br />
      <div class="row">
        <div class="col-sm-3">
          <app-card-section
            titulo="{{ 'Series y películas' | translate }} "
            [Session]="Session"
            [cardName]="'series_y_peliculas'"
            (click)="showSeries()"
          ></app-card-section>
        </div>

        <div class="col-sm-3">
          <app-card-section
            titulo="{{ 'Música y karaoke' | translate }} "
            [Session]="Session"
            [cardName]="'musica_y_karaoke'"
            (click)="showMusic()"
          ></app-card-section>
        </div>

        <div class="col-sm-3">
          <app-card-section
            titulo="{{ 'Descubre y aprende' | translate }} "
            [Session]="Session"
            [cardName]="'descubre_y_aprende'"
            (click)="showCrafts()"
          ></app-card-section>
        </div>

        <div class="col-sm-3">
          <app-card-section
            titulo="{{ 'Recorre el mundo' | translate }} "
            [Session]="Session"
            [cardName]="'recorre_el_mundo'"
            (click)="showRecorre()"
          ></app-card-section>
        </div>
      </div>
    </section>
  </div>

  <div class="row justify-content-center">
    <div class="col-12 {{ colorSeccion }} "><br /><br /></div>
  </div>

  <div
    class="row {{ colorSeccion }}"
    style="
      background-image: url(assets/img/background/avator-bg.png);
      min-height: 300px;
    "
  >
    <div class="col-sm-12 text-md-center">
      <div class="row d-flex flex-column flex-md-row" style="margin: 10px">
        <div
          style="min-width: min-content; max-width: fit-content"
          class="my-2 col-3 animate animate__backOutUp"
          *ngFor="let item of arrToPrint; index as i"
        >
          <app-card-media [item]="item"></app-card-media>
        </div>
      </div>
    </div>
  </div>
</div>

<br /><br />
