import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.css']
})
export class CarrouselComponent implements OnInit {
  @Input() arrToPrint = []
  colorSeccion = 'bg-morado'
  constructor() { }

  ngOnInit(): void {
  }

}
