
import {  Component, Input, AfterViewInit, ViewChild, ElementRef,EventEmitter,Output  } from '@angular/core';
import { ConstantesService } from 'src/app/services/constantes.services';

declare var jQuery: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  version = ''
  constructor(
    private Constantes: ConstantesService,
  ) {
    var me = this;
    me.version = this.Constantes.VER
  }
  ngAfterViewInit() {
    let me = this;
  }
}