import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { HttpClient,HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MatInputModule } from '@angular/material/input'
import { MatSliderModule } from '@angular/material/slider'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatTableModule } from '@angular/material/table'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatDialogModule } from '@angular/material/dialog'
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { DialogEmailComponent } from './dialogs/dialog-email/dialog-email.component'
import { DialogMediaItemComponent } from './dialogs/dialog-media-item/dialog-media-item.component'
import { DialogLogInComponent } from './dialogs/dialog-login/dialog-login.component'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { DialogSubscriberComponent } from './dialogs/dialog-subscriber/dialog-subscriber.component'
import { DialogAddCodeComponent } from './dialogs/dialog-add-code/dialog-add-code.component'
import { DialogAddPinComponent } from './dialogs/dialog-add-pin/dialog-add-pin.component'
import { DialogMusicItemComponent } from './dialogs/dialog-music-item/dialog-music-item.component'
import { DialogCraftItemComponent } from './dialogs/dialog-craft-item/dialog-craft-item.component'
import { HomeComponent } from './pages/home/home.component'
import { SuscribeComponent } from './pages/suscribe/suscribe.component'
import { AccederComponent } from './pages/acceder/acceder.component'
import { PrivacyComponent } from './pages/privacy/privacy.component'
import { AboutComponent } from './pages/about/about.component'
import { TerminosPage } from './pages/terminos/terminos.page'
import { MatCarouselModule } from '@ngmodule/material-carousel'
import { OwlModule } from 'ngx-owl-carousel'
import { MatCardModule } from '@angular/material/card'
import { MatToolbarModule } from '@angular/material/toolbar'
import { CatalogoComponent } from './pages/catalogo/catalogo.component'
import { KidsEComponent } from './pages/kidse/kidse.component'

import { CarouselModule } from 'ngx-owl-carousel-o'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatRadioModule } from '@angular/material/radio'
import { VideoPlayerComponent } from './components/video-player/video-player.component'
import { HeaderComponent } from './components/header/header.component'
import { CardSectionComponent } from './components/card-section/card-section.component'
import { CardMediaComponent } from './components/card-media/card-media.component'
import { CardMediaEpisodeComponent } from './components/card-media-episode/card-media-episode.component'


import { FooterComponent } from './components/footer/footer.component'
import { AngularFireModule } from '@angular/fire'
import { AngularFireAnalyticsModule } from '@angular/fire/analytics'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { environment } from '../environments/environment'
import { from } from 'rxjs';
import { DetailComponent } from './pages/detail/detail.component';
import { CarrouselComponent } from './components/carrousel/carrousel.component';
import { WatchComponent } from './pages/watch/watch.component'

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';




// import { SwiperModule } from 'ngx-swiper-wrapper';
// import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto'
// };


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SuscribeComponent,
    AccederComponent,
    DialogEmailComponent,
    DialogMediaItemComponent,
    DialogLogInComponent,
    DialogMusicItemComponent,
    DialogCraftItemComponent,
    DialogSubscriberComponent,
    DialogAddCodeComponent,
    DialogAddPinComponent,
    PrivacyComponent,
    AboutComponent,
    TerminosPage,
    CatalogoComponent,
    KidsEComponent,
    VideoPlayerComponent,
    HeaderComponent,
    CardSectionComponent,
    CardMediaComponent,
    CardMediaEpisodeComponent,
    FooterComponent,
    DetailComponent,
    CarrouselComponent,
    WatchComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSliderModule,
    MatButtonModule,
    MatIconModule,
    HttpClientModule,
    MatGridListModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatCarouselModule.forRoot(),
    OwlModule,
    MatCardModule,
    MatToolbarModule,
    CarouselModule,
    MatSidenavModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatRadioModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    })    
    //SwiperModule
  ],
  providers: [
  //   {
  //   provide: SWIPER_CONFIG,
  //   useValue: DEFAULT_SWIPER_CONFIG    
  // }
],
  entryComponents: [
    DialogEmailComponent,
    DialogMediaItemComponent,
    DialogMusicItemComponent,
    DialogCraftItemComponent,
    DialogLogInComponent,
    DialogSubscriberComponent,
    DialogAddCodeComponent,
    DialogAddPinComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
