<!-- ====================================
  ——— HEADER
  ===================================== -->
<header class="header" id="pageTop" style="margin-bottom: 105px;" >
    <!-- Top Color Bar -->
    <div class="color-bars">
        <div class="container-fluid">
            <div class="row">
                <div class="col color-bar bg-warning d-none d-md-block"></div>
                <div class="col color-bar bg-success d-none d-md-block"></div>
                <div class="col color-bar bg-danger d-none d-md-block"></div>
                <div class="col color-bar bg-info d-none d-md-block"></div>
                <div class="col color-bar bg-purple d-none d-md-block"></div>
                <div class="col color-bar bg-pink d-none d-md-block"></div>
                <div class="col color-bar bg-warning"></div>
                <div class="col color-bar bg-success"></div>
                <div class="col color-bar bg-danger"></div>
                <div class="col color-bar bg-info"></div>
                <div class="col color-bar bg-purple"></div>
                <div class="col color-bar bg-pink"></div>
            </div>
        </div>
    </div>



    <!-- Navbar -->
    <nav class="navbar navbar-expand-md navbar-scrollUp navbar-sticky navbar-white ">
        <div class="container ">
            <a class="navbar-brand " [routerLink]="['/browse/series_y_peliculas']">
                <img class="d-inline-block " src="https://global-toons.imgix.net/www/logo_300.png?w=250&H=300 " alt="Global Toons ">
            </a>
            <button class="navbar-toggler py-2 " type="button " data-toggle="collapse " data-target="#navbarContent " aria-controls="navbarContent " aria-expanded="false " aria-label="Toggle navigation ">
                <i class="fa fa-bars "></i>
            </button>

            <div class="collapse navbar-collapse " id="navbarContent ">
                <ul class="navbar-nav ml-lg-auto ">



                    <li class="nav-item dropdown bg-home ">
                        <a class="nav-link dropdown-toggle active "  href="https://www.globaltoons.tv" data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <i class="fa fa-home nav-icon text-home-color " aria-hidden="true "></i>
                            <span class="tituloBotonBarra">{{ 'home' | translate }} </span>
                        </a>

                    </li>
                    <!-- <li class="nav-item dropdown bg-acceder" >
                        <a class="nav-link dropdown-toggle "  [routerLink]="['/browse/series_y_peliculas']">
                            <i class="fa fa-unlock-alt  nav-icon text-acceder-color" aria-hidden="true "></i>
                            <span class="tituloBotonBarra">{{ 'Catalogo' | translate }}</span>
                        </a>
                    </li>                      -->
                    <!-- <li class="nav-item dropdown bg-acceder" [hidden]="isUserLoggedIn ==true">
                        <a class="nav-link dropdown-toggle "  [routerLink]="['/acceder']">
                            <i class="fa fa-unlock-alt  nav-icon text-acceder-color" aria-hidden="true "></i>
                            <span class="tituloBotonBarra">{{ 'acceder' | translate }}</span>
                        </a>
                    </li> -->
                    <!-- <li class="nav-item dropdown bg-inicio " [hidden]="isUserLoggedIn !=true">
                        <a class="nav-link dropdown-toggle " (click)="acceder()" style="cursor: pointer;">
                            <i class="fa fa-unlock-alt  nav-icon text-inicio-color" aria-hidden="true "></i>
                            <span class="tituloBotonBarra">Inicio</span>
                        </a>
                    </li> -->

                    <li class="nav-item dropdown bg-nosotros ">
                        <a class="nav-link dropdown-toggle " [routerLink]="['/about']" >
                            <i class="fa fa-unlock-alt  nav-icon text-nosotros-color" aria-hidden="true "></i>
                            <span class="tituloBotonBarra">{{ 'nosotros' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item dropdown bg-contacto ">
                        <a class="nav-link dropdown-toggle " href="mailto:soporte@globaltoons.tv">
                            <i class="fa fa-unlock-alt  nav-icon text-contacto-color" aria-hidden="true "></i>
                            <span class="tituloBotonBarra">{{ 'contacto' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item dropdown bg-idioma ">
                        <a class="nav-link dropdown-toggle " href="javascript:void(0) " id="stores " role="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <i class="fa fa-globe nav-icon text-idioma-color" aria-hidden="true "></i>
                            <span class="tituloBotonBarra">{{ 'idioma' | translate }}</span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right " aria-labelledby="stores ">
                            <li>
                                <a class="dropdown-item " href="javascript:void(0)" (click)="cambiarLenguaje('es')" >Español</a>
                                <a class="dropdown-item " href="javascript:void(0)" (click)="cambiarLenguaje('en')" >Ingles</a>
                            </li>
                        </ul>
                    </li>



                    <!-- <li class="nav-item dropdown bg-cuenta " [hidden]="isUserLoggedIn == false ">
                        <a class="nav-link dropdown-toggle " href="javascript:void(0) " role="button " data-toggle="dropdown ">
                            <i class="fa fa-list-ul nav-icon text-cuenta-color" aria-hidden="true "></i>
                            <span class="tituloBotonBarra">Cuenta</span>
                        </a>
                        <ul class="dropdown-menu ">
                            <li>
                                <a class="dropdown-item " href="#">Perfiles</a>
                            </li>

                            <li>
                                <a class="dropdown-item " href="# ">Membresia</a>
                            </li>

                            <li>
                                <a class="dropdown-item " href="#">Soporte</a>
                            </li>
                 
                        </ul>
                    </li> -->

                    <li class="nav-item dropdown bg-salir " [hidden]="isUserLoggedIn == false ">
                        <a class="nav-link dropdown-toggle "  (click)="logOut()">
                            <i class="fa fa-unlock-alt  nav-icon text-salir-color" aria-hidden="true "></i>
                            <span class="tituloBotonBarra">Salir</span>
                        </a>
                    </li>
                    <li class="nav-item dropdown bg-inicio " [hidden]="isUserLoggedIn == false">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </li>
                    <li class="nav-item dropdown bg-inicio " [hidden]="isUserLoggedIn == false">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </li>                    
                    <li class="nav-item dropdown bg-inicio " [hidden]="isUserLoggedIn == false">
                        <a class="nav-link dropdown-toggle " href="#pandilla" >
                            <img [src]='personajeUrl' ><br>
                            {{GtSubscriptionType}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>