<div class="title" mat-dialog-title>
    Suscripción universal
</div>
<div  >
    <div class="row" [hidden]="true">
        <div class="col-md-6 ">

            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="planSeleccionado" [hidden]="true">
                <mat-radio-button class="example-radio-button" *ngFor="let plan of planes" [value]="plan.planId">
                    {{plan.planNombre}}
                </mat-radio-button>
            </mat-radio-group>

        </div>

    </div>


    <div class="row" [hidden]="reference != 'NULL'">
        <div class="col-md-5 ">

            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="formaPago">
                <mat-radio-button value="pagoTdc" name="pagoTdc" class="example-radio-button">
                    <img src="https://global-toons.imgix.net/www/tarjetas.jpg?auto=compress" style="height: 35px;" />
                </mat-radio-button>
                <mat-radio-button value="oxxo" name="pagoPayPal" class="example-radio-button">
                    <img src="https://global-toons.imgix.net/www/oxxo.png?auto=compress" style="height: 35px;" />
                </mat-radio-button>
                <mat-radio-button value="pin" name="pagoPayPal" class="example-radio-button">
                    <img src="https://global-toons.imgix.net/www/pin.png?auto=compress" style="height: 35px;" />
                </mat-radio-button>
                <mat-radio-button [disabled]="true" value="pagoPayPal" name="pagoPayPal" class="example-radio-button">
                    <img src="https://global-toons.imgix.net/www/paypal.png?auto=compress" style="height: 35px;" />
                </mat-radio-button>
                
                <button (click)="tengoCodigo()" mat-flat-button
                style="font-size: 10px; margin-top:10px;border: 1px solid #CCC; ">Tengo código promocional</button>
            </mat-radio-group>


        </div>
        <div class="col-md-6 detallePago">
            <div style="display: flex; flex-direction: column"  [hidden]="formaPago != 'codigo'">
                <div>
                    <h1>
                        Escribe el código promocional
                    </h1>
                    <form [formGroup]="frmCodigo" autocomplete="off" novalidate (ngSubmit)="addCodigo()" fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <br>
                        <mat-form-field style="width: 100%;">
                            <mat-label>Código de acceso</mat-label>
                            <input matInput [(ngModel)]="data.codigo" type="text" name="codigo" formControlName="codigo">
                        </mat-form-field>
                        <br>
                    </form>
                </div>   
                <div style="text-align: right;">
                    <button mat-stroked-button color="accent" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
                    <button mat-stroked-button color="accent" (click)="addCodigo()" [disabled]="!frmCodigo.valid">Usar código</button>
                </div>             
            </div>

            <div style="display: flex; flex-direction: column" [hidden]="formaPago != 'pagoTdc'">
                <div>
                    <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="checkout" submit>
                        <h1>
                            Datos de tarjeta de crédito o de debito
                        </h1>
                        <div class="opps-ammount-2 ">
                            <h3>Monto a pagar</h3>
                            <h2>$ {{monto}} <sup>MXN</sup></h2>
                        </div>                          
                        <label for="card-info"></label>
                        <div id="form-field">
                            <div id="card-info" #cardInfo></div>
                            <!-- <button id="submit-button" type="submit">
                                Pay ${{_totalAmount}}
                            </button> -->
                            <!-- <mat-error id="card-errors" role="alert" *ngIf="cardError">
                                <mat-icon style="color: #f44336">cancel</mat-icon>
                                &nbsp;{{ cardError }}
                            </mat-error> -->
                        </div>
                    </form>
                </div>
                <div style="text-align: right;">
                    <br>
                    <br>
                    <br>
                    <button mat-stroked-button color="accent" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
                    &nbsp;
                    <button [disabled]="btnSuscribirDisabled" mat-stroked-button color="accent"
                        (click)="createStripeToken()" cdkFocusInitial>Suscribirme</button>
                </div>
            </div>
            <div style="display: flex; flex-direction: column"  [hidden]="formaPago != 'pin'">
                <div>
                    <h1>
                        Escribe el PIN
                    </h1>
                  
                    <form  id="frmPin" [formGroup]="frmPin" autocomplete="off" novalidate (ngSubmit)="addPin()"
                        fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <mat-form-field style="width: 100%;">
                            <mat-label>PIN </mat-label>
                            <input matInput [(ngModel)]="dataItem.codigo" type="text" name="codigo"
                                formControlName="codigo">
                        </mat-form-field>
                    </form>
                </div>
                <div style="text-align: right;">
                    <button mat-stroked-button color="accent" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
                    &nbsp;
                    <button mat-stroked-button color="accent" (click)="addPin()" [disabled]="!frmPin.valid">Usar PIN</button>
                
                </div>
            </div>
            <div style="display: flex; flex-direction: column"  [hidden]="formaPago != 'oxxo'">
                <div>
                    <h1>
                        Pago en Oxxo
                    </h1>
                    <div class="opps-ammount-2 ">
                        <h3>Monto a pagar</h3>
                        <h2>$ {{monto}} <sup>MXN</sup></h2>
                        <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                    </div>
                </div>
                <div style="text-align: right;">
                    <button mat-stroked-button color="accent" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
                    &nbsp;
                    <button mat-stroked-button color="accent" (click)="generarOxxo()" >Generar referencia</button>
                
                </div>
            </div>
        </div>
        <div class="col-md-1 ">

        </div>
    </div>
    <div class="opps" [hidden]="reference == 'NULL'" style="height: 350px; overflow-y: scroll;">
        <div class="opps-header">
            <div class="opps-reminder">Ficha digital. No es necesario imprimir.</div>
            <div class="opps-info">
                <div class="opps-brand"><img src="https://raw.githubusercontent.com/conekta-examples/oxxopay-payment-stub/master/demo/oxxopay_brand.png" alt="OXXOPay"></div>
                <div class="opps-ammount">
                    <h3>Monto a pagar</h3>
                    <h2>$ {{monto}} <sup>MXN</sup></h2>
                    <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                </div>
            </div>
            <div class="opps-reference">
                <h3>Referencia</h3>
                <h1>{{reference}}</h1>
                <img [src]="barcode_url" />
            </div>
        </div>
        <div class="opps-instructions">
            <h3>Instrucciones</h3>
            <ol>
                <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/"
                        target="_blank">Encuéntrala aquí</a>.</li>
                <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de
                    venta.</li>
                <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar
                        que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
            </ol>
            <div class="opps-footnote">Al completar estos pasos recibirás un correo <strong>nuestro</strong> confirmando tu pago.</div>
        </div>
        <div style="text-align: right;">
            <button mat-stroked-button color="accent" (click)="closeOxxo()" cdkFocusInitial>Cerrar</button>
        
        </div>        
    </div>  
    <div class="row" style="margin-top: 15px;">
        <div class="col-md-12 ">
            <div *ngIf="cargando">

                <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
            <div class="alert alert-danger" *ngIf="cardError ">
                {{cardError}}
            </div>
            <div *ngIf="!cargando">
                <mat-progress-bar></mat-progress-bar>
            </div>
        </div>

    </div>


</div>