<div class="title" mat-dialog-title>
    <span> Agrega un código de acceso </span>
</div>
<div mat-dialog-content>
    <form [formGroup]="ownerForm" autocomplete="off" novalidate (ngSubmit)="addCodigo()" fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
        <br>
        <mat-form-field style="width: 100%;">
            <mat-label>Código de acceso</mat-label>
            <input matInput [(ngModel)]="data.codigo" type="text" name="codigo" formControlName="codigo">
        </mat-form-field>
        <br>
    </form>

</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
    <button mat-stroked-button color="accent" (click)="addCodigo()" [disabled]="!ownerForm.valid">Usar código</button>
    <!-- <button mat-stroked-button color="accent" (click)="recuperar()" [disabled]="!ownerForm.valid" style="float: right;">Recuperar</button> -->
</div>