<div class="title" mat-dialog-title > 
  
  <mat-icon class="icon"  >key</mat-icon>  
  <span > Ingresar </span>

</div>
<div mat-dialog-content>
  <p>¿En qué correo quieres recibir la cotización?</p>
  <form [formGroup]="ownerForm" autocomplete="off" novalidate (ngSubmit)="createOwner(ownerForm.value)" fxLayout="column wrap"
      fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-form-field style="width: 100%;">
        <mat-label>Correo</mat-label>
        <input  matInput [(ngModel)]="data.email"  type="email" name="email"  [email]="true" formControlName="email">
      </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
  <button [disabled]="!ownerForm.valid" mat-raised-button color="primary" [mat-dialog-close]="data.email" >Enviar</button>
</div>

