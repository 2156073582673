

<div class="main-wrapper home-interiores">


    <div class="container ">
        <div class="row mt-5 mt-lg-0 pt-5 pt-md-0">
            <div class="col-12">
                <div class="text-center">
                    <h1 class="text-primary">Aviso de privacidad</h1>
                </div>
            </div>
        </div>
        <!-- .row -->
    </div>
    <!-- .container -->

    <div class="container ">
        <div class="row">
            <div class="col-md-12 offset-md-1 legals-content roman">

                <p>En pleno cumplimiento a las obligaciones que establece la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, consciente de la importancia y trascendencia que tiene la adecuada protección y privacidad de los
                    Datos Personales, se pone a disposición de las personas que proporcionen datos personales a la empresa <strong>GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V.</strong>, cualquiera que sea el motivo y medio en virtud del cual le entreguen información,
                    a fin de dar tratamiento de sus Datos, el siguiente:</p>

                <h2 id="definiciones">Definiciones</h2>
                <dl>
                    <dt>Aviso</dt>
                    <dd>El presente Aviso de Privacidad.</dd>
                    <dt>Bloqueo</dt>
                    <dd>El bloqueo implica la identificación y conservación de los Datos Personales, una vez que se cumpla la finalidad para la cual fueron recabados y tiene como propósito determinar las posibles responsabilidades en relación con su tratamiento,
                        hasta el plazo de prescripción legal o contractual de éstas. Durante dicho periodo, sus Datos Personales no serán objeto de tratamiento y transcurrido éste, se procederá con su cancelación en la base de datos o archivo correspondientes;</dd>
                    <dt>Datos</dt>
                    <dd>Los datos personales que obtiene el Responsable y que se describen en el apartado III del presente Aviso.</dd>
                    <dt>Datos Financieros</dt>
                    <dd>Los descritos en el apartado III, numeral 3 de este Aviso y que consisten en aquellos datos mínimos e indispensables a fin de que GLOBAL TOONS pueda realizar el cobro correspondiente a sus servicios de intermediación.</dd>
                    <dt>Datos Personales</dt>
                    <dd>Los descritos en el numeral 1 del apartado III de este Aviso y que se refieren a cualquier información concerniente a una persona física identificada o identificable.</dd>
                    <dt>Datos Sensibles</dt>
                    <dd>Los descritos en el apartado III, numeral 2 del presente Aviso y que son aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo
                        grave para éste; en particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación
                        sindical, opiniones políticas, preferencia sexual.</dd>
                    <dt>Derechos ARCO</dt>
                    <dd>
                        Significa los derechos de acceso, rectificación, cancelación y oposición que, de conformidad con lo establecido por la LFPDP y sujeto a las excepciones establecidas en la misma y en este Aviso de Privacidad, cada Titular tiene, en relación con los Datos
                        Personales recabados por GLOBAL TOONS y/o sus Encargados (cuando los haya), y que se describen a continuación:
                        <dl>
                            <dt>Derecho de Acceso</dt>
                            <dd>Es el derecho que tiene el Titular a conocer sobre los Datos Personales relacionados a su persona que obren en poder de GLOBAL TOONS, de sus Encargados (cuando los haya) o de a quien hayan sido compartidos o transmitidos, de
                                acuerdo con el presente Aviso;</dd>
                            <dt>Derecho a la Rectificación</dt>
                            <dd>Es el derecho de cada Titular para que sus Datos Personales sean rectificados cuando éstos sean inexactos o incompletos;</dd>
                            <dt>Derecho de Cancelación</dt>
                            <dd>Es el derecho que tiene cada Titular para solicitar, en cualquier momento y bajo los términos previstos en la LFPDP y en este Aviso, que se supriman sus Datos Personales, lo cual sucederá una vez que transcurra el periodo de
                                Bloqueo; y</dd>
                            <dt>Derecho de Oposición</dt>
                            <dd>Es el derecho que en todo momento tiene el Titular para solicitar, siempre que tenga una causa legítima de conformidad con la LFPDP, que GLOBAL TOONS deje de tratar sus Datos Personales;</dd>
                        </dl>
                    </dd>
                    <dt>Encargado</dt>
                    <dd>Para atender a este punto, atender a lo previsto en el apartado VI del presente Aviso.</dd>
                    <dt>Global Toons</dt>
                    <dd>GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V</dd>
                    <dt>Instalaciones</dt>
                    <dd>Son los inmuebles, sean propiedad o en uso (por cualquier título legítimo) de Global Toons, en los que se desarrollan actividades del Responsable, incluyendo, sin limitar, prestación de servicios, atención a clientes, promoción de
                        bienes y servicios.</dd>
                    <dt>Ley o LFPDP</dt>
                    <dd>La Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Al hacer mención de ella, hacemos referencia a la normatividad aplicable y constitucionalmente derivada de ella.</dd>
                    <dt>Responsable</dt>
                    <dd>La persona indicada en el apartad II de este Aviso.</dd>
                    <dt>Titulares</dt>
                    <dd>Las personas físicas que proporcionan sus Datos al Responsable.</dd>
                </dl>


                <h2 id="responsable-del-tratamiento-de-los-datos">Responsable del tratamiento de los datos</h2>
                <p><strong>GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V.</strong>, con domicilio ubicado en Adolfo Prieto número 1213, Colonia Del Valle, Delegación Benito Juárez, Ciudad de México, C.P. 03100.</p>


                <h2 id="datos-personales">Datos personales</h2>
                <p>Los Datos que se recaban son los siguientes:</p>
                <dl>
                    <dt>Datos personales</dt>
                    <dd>
                        Nombre, domicilio, fecha de nacimiento; copia de identificación oficial, teléfonos (de casa, móvil y oficina, en su caso), Registro Federal de Contribuyentes, dirección de correo electrónico, firma (autógrafa, electrónica o digital); datos sobre infraestructura
                        tecnológica ay de servicios (compañía proveedora de servicios, nombre del titular de estos servicios, servicios contratados, equipo asignado, lugar de instalación de acceso a los mismos, particularidades de las características
                        tecnológicas, como amplitud de banda); datos sobre ciertas características físicas para finalidades secundarias; también puede recolectar Datos personales de manera indirecta a través de fuentes de acceso público y de otras fuentes
                        disponibles en el mercado que sean de carácter legal; así como datos relacionados con preferencias de consumo y navegación al hacer uso de los servicios web que le brindamos; recolectamos datos al navegar en los sitios y páginas
                        web de GLOBAL TOONS: Dentro de las herramientas de captura automática de datos utilizadas por GLOBAL TOONS en su portal web se encuentran las cookies, los Web beacons, y los enlaces en los correos electrónicos

                        <ol type="i">
                            <li>Uso de Cookies.- El correcto funcionamiento de los sitios de GLOBAL TOONS y el de los Encargados del tratamiento (cuando los haya), requieren de la habilitación de "cookies" en su navegador de Internet; las "cookies" son pequeños
                                archivos de Datos transferidos por el sitio Web al disco duro de su computadora cuando navega por el sitio; las cookies pueden ser por sesión (al cavar una sesión, desaparecen del ordenador) o permanentes (que se quedan
                                en las computadoras hasta que son eliminan o caducan); en todo caso, usted puede ajustar las preferencias de su navegador para aceptar o rechazar las cookies, mas considere que la desactivación de las cookies puede inhabilitar
                                diversas funciones de los sitios web de GLOBAL TOONS o que éstos no se muestren correctamente;</li>
                            <li>Uso de Web beacons (también conocidos como etiquetas de Internet, etiquetas de píxel y clear GIFs).- Son objetos que GLOBAL TOONS puede utilizar en su sitios web y en sus correos electrónicos con formato HTML, los Web beacons,
                                solos o en combinación con las cookies, para recopilar información sobre el uso de los sitios web y su interacción con el correo electrónico;</li>
                            <li>Enlaces en los correos electrónicos de GLOBAL TOONS.- Son vínculos que incluyen los correos electrónicos que permiten a GLOBAL TOONS saber si usted activó dicho vínculo y visitó la página web de destino, pudiendo esta información
                                ser incluida en su perfil; en caso de que usted prefiera que GLOBAL TOONS no recolecte información sobre su interacción con dichos vínculos, usted puede optar por modificar el formato de las comunicaciones de GLOBAL TOONS
                                o puede hacer caso omiso del vínculo y no acceder a su contenido.</li>
                        </ol>
                    </dd>
                    <dt>Datos personales sensibles</dt>
                    <dd>La Responsable no recaba Datos de carácter sensible y, en caso de que llegasen a su poder este tipo de datos, serán desechados inmediatamente; y</dd>
                    <dt>Datos financiero</dt>
                    <dd>Se recabarán y tratarán Datos Financieros de los Titulares con los que se establezca relaciones de crédito en cualquier forma, o bien, para fines de pago de los servicios de GLOBAL TOONS. Tales datos pueden incluir datos de tarjeta
                        de crédito o débito, situación en el buró de crédito, obligaciones susceptibles de valoración económica, tipo de vivienda, datos físicos del inmueble. Los Datos Personales del Titular son recolectados y tratados por el Responsable
                        o sus Encargados (cuando los haya) con la finalidad de permitirle al Titular, llevar a cabo las actividades con el Responsable que se identifican en el numeral IV siguiente.</dd>
                </dl>


                <h2 id="finalidades-del-tratamiento-de-los-datos">Finalidades del tratamiento de los datos</h2>
                <dl>
                    <dt>Finalidades Primarias</dt>
                    <dd>
                        GLOBAL TOONS tratará los Datos Personales del Titular con la finalidad de llevar a cabo las actividades y gestiones enfocadas al cumplimiento de las obligaciones originadas y derivadas de cualquier relación jurídica y comercial que establezcamos con motivo
                        de la prestación de nuestros servicios, facturación, cobranza, crédito, atención a clientes, otorgamiento de garantías, gestión de servicios de valor agregado y contenidos, administración de aplicaciones y sitios Web, gestión de
                        dominios, cuentas de correo a través del servicio distribución de correos promocionales, contacto con el cliente; así como, proporcionar, cambiar o cancelar los servicios que nos solicita el Titular, lo que incluye la intermediación
                        en la prestación de servicios de televisión restringida vía satélite y contenidos en línea de películas, series, documentales, eventos y programación infantil. Asimismo, los Datos Personales se usarán con fines estadísticos, para
                        realizar estudios sobre los datos demográficos, intereses y comportamiento de sus clientes, consumidores, proveedores y de aquellos terceros con los que trate, así como efectuar estudios de mercado y de consumo a efecto de adquirir
                        y ofrecer productos y servicios personalizados, elaborar estadísticas internas que indiquen los servicios y productos más apreciados por los diferentes segmentos de sus clientes, consumidores, proveedores y otros usuarios de los
                        servicios que GLOBAL TOONS brinda. Los Datos Personales se usarán igualmente para gestión de dominios, gestionar los servicios a los cuales el Titular pueda acceder, mantener un registro de las operaciones e información revisada
                        en las distintas secciones de los servicios que GLOBAL TOONS brinda (incluyendo el de intermediación) y que se recolecta a través de herramientas de captura automática de datos; así como controlar el acceso físico y lógico a instalaciones,
                        servicios e infraestructura tecnológica; lo anterior, a fin de que, entre otros, el titular pueda:
                        <ol type="a">
                            <li>Solicitar, contratar, comprar, cambiar, reclamar garantías, devolver productos o cancelar servicios, solicitar una cotización, información o muestras gratuitas de productos y servicios ofrecidos por GLOBAL TOONS (o en intermediación
                                de ésta), sean éstos prestados por nosotros o por terceros, y la descarga de contenidos y formatos;</li>
                            <li>Notificar a GLOBAL TOONS sobre problemas con sus sitios;</li>
                            <li>Participar en chats y/o foros y/o blogs, Participar en trivias, concursos, rifas, juegos y sorteos;</li>
                            <li>Compartir sus comentarios o sugerencias sobre los productos y servicios.</li>
                        </ol>
                    </dd>
                    <dt>Finalidades Secundarias</dt>
                    <dd>
                        Asimismo, GLOBAL TOONS tratará datos personales para las siguientes finalidades:
                        <ol type="a">
                            <li>Enviar publicidad impresa o a través de medios electrónicos, incluyendo comunicaciones con fines de mercadotecnia en línea, o telemarketing sobre productos y servicios, así como de prospección comercial;</li>
                            <li>Ofrecer la plataforma para creación de perfiles personales, que serán accesibles sólo para el Titular y los Encargados (o, en ausencia de éstos, el Responsable);</li>
                            <li>Realizar encuestas;</li>
                            <li>Enviarle al Titular la notificación de ofertas, avisos y/o mensajes promocionales, a menos que el Titular manifieste expresamente su voluntad de no recibir tales ofertas. Ocasionalmente éstas pueden contener información de
                                proveedores de GLOBAL TOONS u otros terceros;</li>
                            <li>Operar campañas de publicidad y promociones y envío de comunicados a clientes.</li>
                        </ol>
                    </dd>
                    <dt>Programa de Recompensas</dt>
                    <dd>En caso de que los Titulares participen en algún programa de recompensas o plataforma de fidelidad que GLOBAL TOONS active, la información que se obtiene es utilizada con fines proporcionar, analizar, administrar, mejorar y personalizar
                        los servicios y estrategias de mercadotecnia de GLOBAL TOONS; entre ellos, los procesos de registro, suscripción, cobro, redención y entrega de productos y servicios; y para comunicarte y hacerte parte de este y otros temas.</dd>
                </dl>
                <p>En general podemos utilizar la información que recopilamos para:</p>
                <ol type="a">
                    <li>Llevar a cabo el cobro de las diversas suscripciones, productos o servicios que adquieres con nosotros y que puede ser realizada directamente por nuestra empresa o a través de un intermediario contratado por nosotros para tal fin,
                        siempre y cuando hayas dado tu consentimiento respectivo. En este caso, es probable que tu información sea almacenada por estos terceros, únicamente para efecto de realizar cargos recurrentes o facilitar la operación de cobro futuro,
                        siempre y cuando así lo autorices. En ningún caso esta información será utilizada para ser explotada con fines comerciales, promocionales o de ningún otro tipo. El uso de tu información por estos proveedores, se encuentra sujeto
                        a su aviso de privacidad.</li>
                    <li>Procesar el envío a tu domicilio, teléfono móvil, correo electrónico o a bajo cualquier otro mecanismos, de los productos, boletos, servicios, promociones, información, contenido, notificaciones y cualquier otro objeto o información
                        que sea necesaria para nuestra correcta operación o que el Titular nos solicite, contrate, compre o requiera que te hagamos llegar directamente.</li>
                    <li>Determinar la zona geográfica donde se encuentra el Titular para ofrecerle contenidos, promociones o beneficios correspondientes a esa zona; recomendarle establecimientos, eventos o actividades que creamos que pueden ser de su interés;
                        determinar su proveedor de servicio de Internet y tener información suficiente para responder más rápido y mejor a todas sus consultas y solicitudes.</li>
                    <li>Prevenir, detectar, impedir e investigar actividades riesgosas, potencialmente prohibidas o ilegales, como el fraude y hacer cumplir nuestros términos y condiciones.</li>
                    <li>Constantemente realizamos proyectos o actividades especiales con empresas cuyos productos o servicios consideramos que pueden resultar de tu interés; en este caso podremos enviar en conjunto con dichas empresas, promociones y anuncios,
                        según lo permita la Ley.</li>
                    <li>Sorteos, concursos, trivias y encuestas; de manera constante ofreceremos la oportunidad de participar en promociones, descuentos, sorteos, concursos y encuestas a través de nuestras plataformas; estas actividades podrán regirse por
                        políticas de privacidad y/o términos y condiciones independientes de este Aviso de Privacidad; si las disposiciones de las políticas de privacidad o de los términos y condiciones de la estas actividades entraran en conflicto con
                        este Aviso de Privacidad o con los Términos y Condiciones de Uso, prevalecerán esos términos y condiciones o políticas de privacidad independientes; si el Titular participa en una de estas actividades, podremos solicitar determinada
                        información personal además de la establecida en este Aviso de Privacidad; esa información adicional podrá combinarse con otra información de cuenta y podrá ser utilizada según se describe en el presente Aviso.</li>
                </ol>
                <p>Constantemente contratamos otras empresas, agentes o contratistas ("Proveedores de servicios"), que contarán con el carácter de Encargados, para que realicen servicios en nuestro nombre o para que nos ayuden a la prestación del servicio.
                    Algunos de estos proveedores de servicios ofrecen servicios de call center, cobranza, marketing, comunicaciones, mensajería, infraestructura y servicios de tecnología que, además de personalizar y mejorar nuestro servicio, detectan
                    y limitan los intentos de fraude en las transacciones de las tarjetas de crédito y de otras formas de pago, pueden brindar atención al cliente, analizar y mejorar los datos incluyendo la información relativa a la interacción de nuestros
                    usuarios con nuestro sitio web, y procesar nuestras encuestas de consumo. No sobra decir que, con la finalidad de procurar la seguridad en los Datos a nuestro cargo, podemos contratar servicios de terceros para el almacenaje de los
                    mismos en servidores ubicados dentro o fuera del país. Durante la prestación de sus servicios, estas empresas pueden tener acceso a Datos Personales del Titular; pero nosotros no las autorizamos para que usen o divulguen tu información
                    personal, al menos que esté relacionada con los servicios que proporcionan. En particular, se utilizarán los servicios de GLOBAL TOONS para llevar a cabo la promoción y ofrecimiento de productos y servicios, así como para la ejecución
                    de cobros por pertenecer al Programa de Recompensas o de Fidelidad. GLOBAL TOONS podrá compartir los Datos Personales y de medios de pago con empresas con las cuales tenga contrato de operación y/o cobranza de plataformas de lealtad
                    o generación de contenido y beneficios diversos, ya sean éstas electrónicas, digitales o tradicionales. Las empresas encargadas de brindar los contenidos, tendrán un acceso mínimo y restringido a los Datos, limitándose únicamente a
                    usuario y clave, mientras que GLOBAL TOONS administrará y tratará el resto de los Datos.</p>
                <p>El titular puede manifestar su negativa para el tratamiento de sus Datos Personales con relación a las finalidades secundarias conforme lo establece la sección 7 de este aviso, o bien, simplemente no participar del programa de recompensas,
                    para evitar el tratamiento de sus Datos Personales, conforme al párrafo anterior.</p>
                <p>Los Datos de los Titulares, siendo todos ellos los mínimos e indispensables para los fines descritos en este Apartado, se conservarán en archivos electrónicos (salvo por lo que hace a los descritos en el numeral 3), con la finalidad de
                    mantener una base actualizada de los Titulares, de sus necesidades particulares (cuando así lo deseen expresar los Titulares), así como de las condiciones requeridas para los fines descritos en este Apartado. Siempre se conservarán
                    con estricta confidencialidad todos los Datos que haya proporcionado el Titular.</p>
                <dl>
                    <dt>Transferencia y Remisión</dt>
                    <dd>
                        Los Datos serán transferidos, en pleno respeto de los principios de finalidad y lealtad, respetando plenamente la expectativa razonable de privacidad de los Titulares, únicamente a los Encargados que nos apoyan en algún proceso (cuando los haya) y que
                        requieran conocer los Datos, para el debido cumplimiento de los fines descritos en el presente Aviso. GLOBAL TOONS requiere compartir sus Datos Personales con proveedores de servicios de administración y gestión de bases de datos
                        para el tratamiento automatizado de Datos y su almacenamiento, autenticación y validación de correos electrónicos, así como para la contratación de personal y servicios de auditoría; asimismo, se requerirá la transferencia de Datos
                        para los servicios, promociones y recompensas, previstas en la sección IV de este Aviso. En todo caso, GLOBAL TOONS se asegura de que estos Encargados (cuando los haya) asuman obligaciones contractuales que permitan que los Datos
                        de nuestros clientes se encuentren protegidos. La transferencia se llevará a cabo si GLOBAL TOONS lo considera necesario para:
                        <ol type="i">
                            <li>Cumplir con cualquier legislación, reglamentación, proceso legal o solicitud gubernamental correspondiente;</li>
                            <li>Hacer cumplir términos de uso correspondientes, incluida la investigación de posibles infracciones;</li>
                            <li>Detectar, prevenir o abordar actividades ilegales o presuntamente ilegales (incluidas las de pago fraudulento), problemas de seguridad o técnicos;</li>
                            <li>Proteger de daño contra los derechos, la propiedad o la seguridad del Titular, del Responsable, de los Encargados (cuando los haya), de los prestadores de los servicios referentes a promociones o recompensas, sus usuarios o
                                el público, según lo requiera o permita la Ley.</li>
                        </ol>

                        Fuera de los casos descritos en el párrafo anterior, los datos no serán transferidos ni aprovechados de forma alguna a cualquier otra persona o tercero, salvo en los casos siguientes para las finalidades descritas en el presente Aviso:
                        <ol type="i">
                            <li>En los casos en los que se transfieran a las personas que reciban los Datos, sea como resultado de la cesión o licencia de los derechos sobre las marcas de GLOBAL TOONS, o bien, para la prestación de los servicios de intermediación
                                de contenidos para los Titulares;</li>
                            <li>Ocasionalmente, GLOBAL TOONS puede ser parte en alguna transacción corporativa (incluyendo fusiones, escisiones, ventas de activos o acciones, reestructuras corporativas, entre otras transacciones). En tanto que usualmente
                                los Datos son uno de los activos a ser considerados como parte de la transacción, el Responsable puede comunicar los Datos a terceros en el contexto de dichas transacciones. Entre dichos terceros se encuentran, entre otros,
                                los potenciales adquirentes de los activos y los asesores (legales, contables o financieros, entre otros) que participen en la transacción. Únicamente comunicaremos sus datos personales a dichos terceros en la medida que
                                dicha comunicación resulte necesaria para evaluar o completar la transacción o cuando la transferencia resulte necesaria como consecuencia de la misma;</li>
                            <li>Cuando sean requeridos por autoridades administrativas o judiciales, cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, la protección de los derechos del Responsable, la protección
                                de derechos de terceros o para la procuración o administración de justicia;</li>
                            <li>Cuando GLOBAL TOONS, directa o indirectamente, preste o proporcione los servicios y productos referidos en la sección IV de este Aviso; y/o</li>
                            <li>En otros casos, cuando la ley permita esta transferencia sin el consentimiento previo del titular, en términos del artículo 37 de la Ley.</li>
                        </ol>

                        Todas las transferencias anteriores (y las remisiones incluidas los párrafos anteriores) pueden tener el carácter nacional o internacional; asimismo, todas son precisas para el mantenimiento o cumplimiento de la relación comercial con usted y, por lo
                        tanto, GLOBAL TOONS no requiere el consentimiento del cliente para realizarlas; en su caso, GLOBAL TOONS sólo dará aviso conducente por los medios previstos en este Aviso para informar a los Titulares acerca de modificaciones en
                        el mismo. Habiendo leído, entendido y acordado los términos expuestos en este Aviso, el Titular manifiesta su consentimiento para que GLOBAL TOONS realice transferencias de Datos a terceros nacionales o extranjeros, en el entendido
                        de que el tratamiento que dichos terceros den a los Datos Personales del Titular deberá ajustarse a lo establecido en este Aviso. GLOBAL TOONS podrán transferir los Datos Personales recolectados del Titular a cualquier otra sociedad
                        del mismo grupo al que pertenece y que operen con los mismos procesos y políticas internas, sea que se encuentren en territorio nacional o en el extranjero para su tratamiento con las mismas finalidades descritas en este Aviso.
                        También podrá transferir sus Datos a otros terceros que le apoyen para cumplir con los contratos o relaciones jurídicas que tenga con el Titular. Si no desea que sus Datos sean procesados tras el traspaso, deberá ponerse en contacto
                        con el nuevo propietario. No obstante lo dispuesto en esta Sección o en cualquier otro lugar de este Aviso, el Titular reconoce y acepta que el Responsable no requiere de autorización ni su confirmación para realizar transferencias
                        de Datos en los casos previstos, de conformidad con la Ley y este Aviso.
                    </dd>
                </dl>
                <dl>
                    <dt>Confidencialidad y Seguridad</dt>
                    <dd>Los datos personales serán guardados bajo la más estricta <strong>confidencialidad</strong>, adoptando todas las medidas de seguridad administrativa, técnicas y jurídicas necesarias para tales efectos y no se les podrá dar un uso distinto
                        a los antes mencionados, salvo que medie un cambio en este Aviso de Privacidad (en cuyo caso, se dará aviso en términos de la Ley y su Reglamento).</dd>
                    <dt>Bloqueo</dt>
                    <dd>Una vez que se cumpla la finalidad del tratamiento de Datos, o bien, que un Titular deje de recibir servicios de GLOBAL TOONS, los Datos serán bloqueados con el único propósito de determinar posibles responsabilidades en relación con
                        su tratamiento, hasta el plazo de prescripción legal previsto en el artículo 11 de la Ley. Durante dicho periodo, los datos personales no serán objeto de tratamiento y transcurrido éste, se procederá a su cancelación en la base
                        de datos que corresponde.</dd>
                </dl>


                <h2 id="recoleccion-de-datos-personales">Recolección de datos personales</h2>
                <p>Para la recolección de Datos, seguimos todos los principios que marca la Ley como la licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.</p>
                <p>Los Datos serán recabados directamente del Titular de forma personal o a través de otros medios ópticos, sonoros, visuales, o por cualquier otra tecnología legalmente permitida, ya sea mediante entrevistas, en nuestra página de internet
                    o servicios en línea, entre otras. Los Datos serán recolectados primordialmente vía Internet; en todo caso, el Titular tendrá acceso al presente Aviso de conformidad con los principios descritos en el párrafo anterior, así como con
                    estricta confidencialidad y seguridad.</p>
                <p>No se recabarán Datos de menores de edad, incapaces o interdictos. Deberá proporcionarse igualmente documento que acredite la calidad de la persona quien está otorgando el consentimiento. En todo caso, GLOBAL TOONS se basa en la buena
                    fe de las personas que acuden y, en caso de manifestar que se trata de mayores de edad, se presume que lo son.</p>


                <h2 id="encargado">Encargado</h2>
                <p>La persona principal encargada del tratamiento total de los Datos es la misma Responsable, esto es, GLOBAL TOONS CHANNEL, S.A.P.I. DE C.V., a quien puede ser contactada vía correo electrónico en la dirección electrónica <a href="mailto:contacto@globaltoons.tv">contacto@globaltoons.tv</a>,
                    o bien llamando al <a href="tel:5555406849">+52 (55) 55406849</a>, así como a través de cualquiera de nuestras redes sociales, en un horario de atención de Lunes a Jueves de 11:00 a 17:00 hrs. y Viernes de 11:00 a 14:00 hrs. (tiempo
                    del centro de la Ciudad de México), o bien, directamente en su domicilio, según se indica en el Apartado II de este Aviso.</p>
                <p>Consecuentemente con lo anterior, cuando el presente Aviso se refiere al ‘Encargado’, se entenderá que se está hablando del mismo Responsable, quien es la única persona a cargo del Tratamiento de los Datos Personales.</p>


                <h2 id="mecanismos-para-manifestar-su-consentimiento">Mecanismos para manifestar su consentimiento</h2>
                <p>Para el tratamiento de sus datos personales sensibles y financieros descritos en el numeral 3 del apartado III de este Aviso, en los términos contenidos en este Aviso y para las finalidades antes descritas, se entiende que se otorga el
                    consentimiento expreso, en el momento de recabar los Datos de acuerdo con el apartado anterior.</p>


                <h2 id="opciones-y-medios-para-limitar-el-uso-y-divulgacion-de-sus-datos">Opciones y medios para limitar el uso y divulgación de sus datos</h2>
                <p>Para limitar el uso y divulgación de sus Datos, el Titular podrá realizarlo a través de un escrito o comunicado libre dirigido al Encargado (o, en ausencia de éste, el Responsable) de alguna de las siguientes formas:</p>
                <ol type="a">
                    <li>Enviado impreso al domicilio del Responsable indicado en el apartado II de este Aviso, dirigido al Encargado (o, en ausencia de éste, el Responsable);</li>
                    <li>Enviado impreso al Responsable, dirigiéndolo al Encargado (o, en ausencia de éste, el Responsable);</li>
                    <li>Vía correo electrónico al Encargado (o, en ausencia de éste, el Responsable), en la dirección descrita en el apartado VI de este Aviso.</li>
                </ol>
                <p>De acuerdo con la Ley y el Reglamento, la solicitud deberá satisfacer los requisitos previstos en el apartado XI del presente Aviso.</p>


                <h2 id="ARCO">Ejercicio de derechos de acceso, rectificación, cancelación y oposición (ARCO)</h2>
                <p>El ejercicio de los derechos de acceso, rectificación, cancelación, oposición y la revocación del consentimiento deberá realizarse por escrito o comunicado libre dirigido al Encargado (o, en ausencia de éste, el Responsable) de alguna
                    de las siguientes formas:</p>
                <ol type="a">
                    <li>Enviado impreso al domicilio del Responsable indicado en el apartado II de este Aviso, dirigido al Encargado (o, en ausencia de éste, el Responsable);</li>
                    <li>Enviado impreso al Responsable, dirigiéndolo al Encargado (o, en ausencia de éste, el Responsable);</li>
                    <li>Vía correo electrónico al Encargado (o, en ausencia de éste, el Responsable), en la dirección descrita en el apartado VI de este Aviso.</li>
                </ol>
                <p>De acuerdo con la Ley y el Reglamento, la solicitud deberá satisfacer los requisitos previstos en el apartado XI del presente Aviso.</p>


                <h2 id="revocacion-del-consentimiento">Revocación del consentimiento</h2>
                <p>En el caso de que su petición consista en revocar su consentimiento que otorgó para el tratamiento de sus datos, deberá hacerlo a través de escrito o comunicado libre dirigido al Encargado (o, en ausencia de éste, el Responsable) de alguna
                    de las siguientes formas:</p>
                <ol type="a">
                    <li>Enviado impreso al domicilio del Responsable indicado en el apartado II de este Aviso, dirigido al Encargado (o, en ausencia de éste, el Responsable);</li>
                    <li>Enviado impreso al Responsable, dirigiéndolo al Encargado (o, en ausencia de éste, el Responsable);</li>
                    <li>Vía correo electrónico al Encargado (o, en ausencia de éste, el Responsable), en la dirección descrita en el apartado VI de este Aviso.</li>
                </ol>
                <p>De acuerdo con la Ley y el Reglamento, la solicitud deberá satisfacer los requisitos previstos en el apartado XI del presente Aviso.</p>
                <p>La revocación de su consentimiento <strong>no tendrá efectos retroactivos.</strong></p>


                <h2 id="procedimiento-de-atencion-de-solicitudes">Procedimiento de atención de solicitudes</h2>
                <p>La solicitud del Titular para los efectos descritos en los apartados VIII, IX y X deberá contener lo siguiente:</p>
                <ol>
                    <li>Nombre completo y domicilio actual y/o de preferencia dirección de correo electrónico para ser notificado de la respuesta;</li>
                    <li>En caso de que autorice a personas para oír notificaciones, deberá señalarlo expresamente y acompañar carta poder ante dos testigos en favor de dichas personas;</li>
                    <li>Anexar copia de identificación oficial, como credencial para votar, pasaporte o cédula profesional.</li>
                    <li>En caso de que se tramite mediante representante o por la persona que ejerce la patria potestad sobre el Titular de los Datos, deberá anexar copia de identificación oficial del Titular y del representante, así como el documento por
                        virtud del cual acredita su carácter de representante.</li>
                    <li>
                        Señalar la petición que se formula:
                        <ol type="i">
                            <li>En el caso de la limitación, indicar la limitación que se pretende hacer;</li>
                            <li>En caso del ejercicio de Derechos ARCO, indicar los Datos a los que desea acceder, rectificar, cancelar o tratamiento al que desea oponerse;</li>
                            <li>En caso de revocación, indicar la intención de revocar el consentimiento otorgado para el tratamiento de Datos.</li>
                        </ol>
                    </li>
                    <li>En la medida de lo posible, el solicitante podrá facilitar la búsqueda de sus Datos, proporcionando la fecha aproximada en que los Datos fueron recolectados y la forma y lugar en que fueron proporcionados.</li>
                </ol>
                <p>En caso de que la solicitud omita o resulte incompleto o erróneo domicilio o medio de notificación, se tendrá por no presentada la solicitud, en términos del artículo 94 del Reglamento. En caso de que esté incompleta la solicitud o se
                    requiera más información, el Responsable requerirá dentro de los primeros 5 días hábiles al Titular para que satisfaga el requerimiento correspondiente dentro de un término de 10 días hábiles.</p>
                <p>El Responsable contará con un plazo máximo de 20 días hábiles para informarle sobre la procedencia de la misma y, si resulta procedente, se hará efectiva la misma dentro de los 15 días hábiles siguientes a la fecha en que se comunica la
                    respuesta correspondiente.</p>
                <p>En caso de que se trate de una solicitud de acceso, referente a copias de los documentos que obren en posesión del Responsable, se hará entrega de éstas cuando el Titular o su representante haga el pago correspondiente del costo de dichas
                    copias, mismo que será comunicado en la respuesta que se dé en los términos del párrafo anterior. Para estos casos, será necesario que el Titular o su representante legal se identifiquen plenamente ante el Responsable (no se entregará
                    documentos e información a las personas autorizadas en términos del número 2 de este apartado) y, en el caso de represente, éste presente el original del documento por virtud del cual acredita su representación, para cotejo.</p>
                <p>En caso de que la información proporcionada por el Titular no sea suficiente para la localización o identificación de los Datos, el Responsable podrá ampliar los plazos previstos en el segundo párrafo de este apartado, notificándoselo
                    al Titular.</p>
                <dl>
                    <dt>Notificaciones</dt>
                    <dd>
                        Para la comunicación de la respuesta o requerimientos adicionales de información, se procederá de la siguiente forma:
                        <ol type="a">
                            <li>Si el Titular proporcionó un medio electrónico en los términos del numeral 1 de este apartado, se le enviará un comunicado por esta vía y se entenderá que surte efectos el mismo día en que es enviado.</li>
                            <li>Si el Titular proporciona un domicilio físico, las comunicaciones se enviarán vía correo certificado, para efectos de lo cual, el Titular deberá proporcionar los recursos económicos para llevar a cabo la notificación; en caso
                                de que el Titular no brinde los recursos, el Responsable o Encargado (cuando lo haya) cumplirá con dejar la respuesta a su disposición en el lugar donde inició el trámite, para que el Titular o su representante acudan a
                                recoger la respuesta.</li>
                        </ol>
                    </dd>
                    <dt>Negativa</dt>
                    <dd>El Responsable o Encargado (cuando lo haya) sólo podrá ser resuelto en forma negativa, en los términos previstos para tales efectos en términos de la Ley o el Reglamento.</dd>
                </dl>


                <h2 id="opciones-adicionales-y-medios-para-limitar-el-uso-o-divulgacion-de-los-datos-personales">Opciones adicionales y medios para limitar el uso o divulgación de los datos personales</h2>
                <p>Con el objeto de que el Titular pueda limitar el uso y divulgación de sus Datos, se pone a disposición del Titular los siguientes medios adicionales:</p>
                <ul>
                    <li>
                        Si inscripción en el Registro Público para Evitar Publicidad (REPEP), que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de no recibir publicidad vía telefónica de proveedores de sectores de:
                        <ol>
                            <li>Telecomunicaciones.</li>
                            <li>Turismo.</li>
                            <li>Comercial, ni que su información sea utilizada con fines mercadotécnicos o publicitarios. Para mayor información sobre este registro, usted puede consultar el portal de internet de la Procuraduría Federal del Consumidor, o
                                bien, ponerse en contacto directo con ésta.</li>
                        </ol>
                    </li>
                    <li>Enviar un correo electrónico al Encargado (o, en ausencia de éste, el Responsable), manifestando su negativa a seguir recibiendo publicidad por parte del responsable.</li>
                </ul>


                <h2 id="legislacion-y-jurisdiccion-aplicables">Legislación y jurisdicción aplicables</h2>
                <p>Al expresar el consentimiento del presente Aviso, Titular, Responsable y Encargados (cuando los haya), se someten a la jurisdicción y legislación aplicables en la Ciudad de México, con independencia del domicilio actual o futuro con el
                    que cuenten a la fecha de expresar el consentimiento.</p>
                <p>La Ley y demás normatividad aplicable pueden ser consultados en <a href="http://ordenjuridico.gob.mx/">http://ordenjuridico.gob.mx/</a> o en <a href="http://inicio.inai.org.mx/SitePages/marcoNormativo.aspx">http://inicio.inai.org.mx/SitePages/marcoNormativo.aspx</a>.</p>


                <h2 id="cambios-en-el-aviso-de-privacidad">Cambios en el aviso de privacidad</h2>
                <p>El presente Aviso, así como sus modificaciones, estarán a su disposición en la página <a href="https://globaltoons.tv">https://globaltoons.tv</a>, a través de comunicados mediante cualquier medio de comunicación que tengamos con usted,
                    razón por lo cual, agradeceremos nos informen de cualquier cambio en sus Datos, para mantenerlo al tanto de cualquier cambio a ese respecto.</p>
                <p>GLOBAL TOONS se reserva el derecho de actualizar periódicamente el presente Aviso para reflejar los cambios de nuestras prácticas de información. Es responsabilidad del Titular revisar periódicamente el contenido del Aviso descrito, en
                    donde se publicarán los cambios realizados conjuntamente con la fecha de la última actualización. El Responsable, entenderá que, de no expresar lo contrario, significa que el Titular ha leído, entendido y acordado los términos ahí
                    expuestos, lo que constituye su consentimiento a los cambios y/o actualizaciones establecidos en dichas actualizaciones respecto al tratamiento de sus Datos para efectos de la Ley.</p>

            </div>
            <!-- .col -->
        </div>
        <!-- .row -->
    </div>
    <!-- .container -->



</div>

