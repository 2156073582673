<figure  #videoContainer  data-fullscreen="false">
  
    <video #videoPlayer  controls="" preload="metadata" autoplay > 
        <source src="{{videoToPlay.url}}" type="video/mp4">
        <!-- <source src="video/sintel-short.webm" type="video/webm">
        <track label="English" kind="subtitles" srclang="en" src="subtitles/vtt/sintel-en.vtt" default>
        <track label="Deutsch" kind="subtitles" srclang="de" src="subtitles/vtt/sintel-de.vtt">
        <track label="Español" kind="subtitles" srclang="es" src="subtitles/vtt/sintel-es.vtt"> -->
    </video>
    <div  #videoControls  class="controls" data-state="shidden">
        <button #playpause type="button" data-state="play">Play/Pause</button>
        <button #stop type="button" data-state="stop">Stop</button>
        <div class="progress">
            <progress #progressDiv value="0" min="0">
                <span #progressBar></span>
            </progress>
        </div>
        <button #mute type="button" data-state="mute">Mute/Unmute</button>
        <button #volinc type="button" data-state="volup">Vol+</button>
        <button #voldec type="button" data-state="voldown">Vol-</button>
        <button #fs type="button" data-state="go-fullscreen">Fullscreen</button>

    </div>


    <figcaption>
        <button #subtitles type="button" data-state="subtitles" style="display: none;">CC</button>
    </figcaption>
</figure>
<!-- <script src="js/video-player.js"></script> -->