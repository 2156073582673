import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import {MatSidenavModule} from '@angular/material/sidenav';
import { Router, ActivatedRoute ,NavigationEnd} from '@angular/router'


import { VideoPlayerComponent } from '../../components/video-player/video-player.component';
import { StorageService } from 'src/app/services/storage.services';
export interface MediaData {
  id: string;
  title: string;
  synopsis: string;
  poster: string;
}


@Component({
  selector: 'dialog-music-item',
  templateUrl: './dialog-music-item.component.html',
  styleUrls: ['./dialog-music-item.component.css'],
})

export class DialogMusicItemComponent {

  @ViewChild("appVideoPlayer") appVideoPlayer: VideoPlayerComponent;

  arrVideos = [{
    Id_GtMedia: 0,
    Id_GtVideo: 0,
    poster: "",
    synopsis: "",
    title: "",
    type: "video/mp4",
    url: "",
  }];
  titulo = "";
  subTitulo = "";
  videoActual : any
  openPlayer = false;
  public ownerForm: FormGroup;

  dataItem: {
    id: string;
    title: string;
    synopsis: string;
    poster: string;
  };

  indexCurrentVideo = 0
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 300,
    navText: ['Anterior', 'Siguiente'],
    responsive: {
      0: {
        items: 5
      },
      400: {
        items: 5
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    animateOut: 'slideOutUp',
    animateIn: 'slideInUp',
    nav: false
  }


  constructor(
    private DataSrv: DataService,
    // private appVideoPlayer:  VideoPlayerComponent,
    public dialogRef: MatDialogRef<DialogMusicItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MediaData,
    private storage: StorageService ,
    private router: Router,
  ) {
    let me = this;
    me.dataItem = data


  }
  goToSuscribe(timeLap:any){
    this.dialogRef.close("");
    this.router.navigate(['/suscribe', "Full"])
    // [routerLink]="['/suscribe','full']"
    window.scrollTo(0, 0)
  }  
  timeUpdate(timeLap:any){
    let me = this;

    if (timeLap.currentTime >= timeLap.duration) {
      me.autoPlay()
      // me.video.pause();
      // me.video.src = me.srcSpot
      // me.video.play();
    }
  }

  autoPlay() {
    let me = this;
    if(me.indexCurrentVideo <= (me.arrVideos.length -1)){

      me.videoActual =me.arrVideos[me.indexCurrentVideo]
      if(me.indexCurrentVideo == 0 ){
        me.videoActual.skipIntro = false
        me.videoActual.timeIntro = 0
      }      
      me.appVideoPlayer.setVideoToPlay(me.videoActual)
      me.subTitulo = me.videoActual.title      
      me.indexCurrentVideo++
    }else{
      me.indexCurrentVideo = 0
    }

    // me.video.src = me.videos[me.index].url
    // me.video.play();

  }

  ngAfterViewInit() {
    let me = this;
    const session = me.storage.getItem("Session")
    const usuario = me.storage.getItem("User")        
    let args = {
      "ClassName": "GtVideos_X_Media",
      "Action": "Get",
      "type": 'musicals',
      "Session" :session,
      "db": "Prometeo"
    };
    me.DataSrv.executeForCRUD(args).subscribe(response => {
      if (response.success) {
        me.arrVideos = response.data
        me.openPlayer = true;

        me.arrVideos.forEach((ele,index)=>{
          if( ele.Id_GtMedia == parseInt(me.dataItem.id)){
            me.indexCurrentVideo = index
            
          }
        });

        me.videoActual = me.arrVideos[me.indexCurrentVideo]
        //me.subTitulo = me.arrVideos[0].title
        me.autoPlay();
      }
    });

    
  }

  ngOnInit() {
    this.ownerForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  getImagen(video) {
    let transparencia = "&con=-75"
    switch (video.free_time) {
      case 0:
        transparencia = "&con=-75"
        break
      case 50:
        transparencia = "&con=-25"
        break
      case 100:
        transparencia = "&con=-0"
        break
      default:
        transparencia = "&con=-0"

    }
    return video.poster + transparencia
  }

  openVideo(index) {
    let me = this;
    me.indexCurrentVideo = index
    me.autoPlay()
    // me.videoActual = me.arrVideos[index]
    // let video: any = document.getElementById("my_video_1");
    // // video.currentTime = 0;
    // me.subTitulo = me.videoActual.title
    // video.src =  me.videoActual.url
    // video.play();
  }

  onNoClick(): void {
    this.dialogRef.close("");
  }

  createOwner(value: any) {
    let me = this;

  }

}
