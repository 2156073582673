import { Component } from '@angular/core'

import { StorageService } from 'src/app/services/storage.services';
import { Router, ActivatedRoute ,NavigationEnd} from '@angular/router'
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'angular-app'
  isUserLoggedIn = false
  hidefullScreen = true
  constructor(
    private storage: StorageService,
    private router: Router,
    private rutaActiva: ActivatedRoute,    
    private DataSrv: DataService,
    ) {
    let me = this
    me.isUserLoggedIn = me.storage.getItem("Session") != null;
     this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });   


    
    me.storage.changes.subscribe((response) => {
      if(response.key == 'Session' ){
        me.isUserLoggedIn = response.value ? true: false
      }
      if(response.key == 'fullScreen' ){
        me.hidefullScreen = response.value ? true: false
      }
    })       

    this.verificaUsuario()
  }

  verificaUsuario(){
    var me = this;
    me.isUserLoggedIn = false;

    let user = this.rutaActiva.snapshot.params.user
    if( !user ){
      user = localStorage.getItem("User")
    }
    // console.log( user)
    me.isUserLoggedIn = false;
    if(user){

      let args = {
        ClassName: 'GtSubscribers',
        Action: 'logIn',
        Guid: user,
        db: 'Prometeo',
      }
      me.DataSrv.executeForCRUD(args).subscribe((response) => {
        
        if (response.success) {
          
          me.storage.store("GtSubscriptionType", response.data[0].Nombre_GtSubscriptionType );
          me.storage.store("Session", response.data[0].Session );

          me.storage.store("User", response.data[0].Guid );                   
          me.storage.store("Email", response.data[0].Email );                   
          me.storage.store("Personaje", response.data[0].Personaje );
          //me.personajeUrl = `https://global-toons.imgix.net/www/avatars/${response.data[0].Personaje }.png?h=100` ;
          me.isUserLoggedIn = true;
        }
      
      });        
    }else{
      me.isUserLoggedIn = false;
      console.log("SIN USUARIO EN APP C")
    }
    console.log("VErifica Usuario")
  }
}
