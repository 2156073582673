<div
  class="row"
  class="row {{
    colorSeccion
  }} flex flex-column flex-md-row p-5 justify-content-center"
  style="
    background-image: url(assets/img/background/avator-bg.png);
    min-height: 300px;
  "
>
  <div class="col-12">
    <h1 class="text-white-title">Episodios</h1>
  </div>

  <div class="col-sm-12 text-md-center">
    <div class="row">
      <div
        class="col-12 col-md-3 my-5"
        *ngFor="let item of arrToPrint; index as i"
      >
        <app-card-media-episode [item]="item"></app-card-media-episode>
      </div>
    </div>
  </div>
</div>
