
import { Component,Input, AfterViewInit, ViewChild, ElementRef,EventEmitter,Output  } from '@angular/core';
declare let jQuery: any
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
 
  constructor() {
    var me = this;
    jQuery("#hiddenToolBar").val(1)
    window.scrollTo(10, 0)
    window.scrollTo(0, 0)    
  }
  ngAfterViewInit() {
    let me = this;


  }
}
